.l-footer {
  background-color: $color-gray-bg;
  font-weight: bold;
  font-size: rem(16);
  padding-top: 4rem;
  padding-bottom: 3.25rem;
  padding-left: var(--padding-x-base);
  padding-right: var(--padding-x-base);

  @media ($min-ms) {
    padding-top: 5rem;
  }

  @media ($max-m) {
    > * + * {
      margin-top: 5rem;
    }
  }

  @media ($min-m) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.l-footer__logo {
  display: flex;
  align-items: center;

  svg {
    fill: currentColor;
    width: rem(362 * 0.7);
    height: rem(26 * 0.7);

    @media ($min-ms) {
      width: rem(362);
      height: rem(26);
    }
  }
}

.l-footer__nav {
  @media ($min-ms) {
    display: flex;
  }

  ul:nth-child(1) {
    display: grid;
    grid-template-columns: minmax(0, auto);
    grid-row-gap: 0.625rem;

    @media ($min-s) {
      grid-template-columns: repeat(2, minmax(0, auto));
      grid-template-rows: repeat(var(--row-count), minmax(0, auto));
      grid-auto-flow: column;
      grid-column-gap: 4.5rem;
      justify-content: start;
    }

    @media ($min-m) and (max-width: #{em(1040)}) {
      grid-column-gap: 2.75rem;
    }
  }

  ul:nth-child(2) {
    @media ($max-ms) {
      margin-top: 3.25rem;
    }

    @media ($min-ms) {
      margin-left: 4.5rem;
    }

    @media ($min-m) and (max-width: #{em(1040)}) {
      margin-left: 3rem;
    }
  }

  a {
    position: relative;

    &::after {
      @include extended-clickarea($y: 5);
    }
  }
}

.l-footer__copyright {
  font-weight: normal;
  margin-top: 4rem;
}
