.c-video {
}

.c-video__wrapper {
  display: grid;
  position: relative;

  > * {
    grid-area: 1 / 1 / 2 / 2;
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(128, 128, 128, 0.1); // Works on most backgrounds
    transition: opacity 1000ms $ease-fade, visibility 0ms linear 1000ms;

    .c-video--dark-bg & {
      background-color: rgba(0, 0, 0, 0.8);
    }
  }

  &--transparent::after {
    opacity: 0;
    visibility: hidden;
  }

  svg {
    width: 100%;
    height: auto;
  }

  video {
    //object-fit: cover; // Safari fullscreen issue
  }
}

.c-video__unmute {
  width: rem(48);
  height: rem(48);
  padding: 0.5rem;
  position: absolute;
  z-index: 1;
  top: 1rem;
  left: 1rem;
  color: $color-white;
  background-color: rgba($color-black, 0.5);
  transition: opacity 500ms $ease-fade, visibility 0ms linear,
    background-color 150ms $ease-fade;
  opacity: 1;
  visibility: visible;

  @media ($min-ms) {
    top: 1.5rem;
    left: 1.5rem;
  }

  &:hover,
  &:focus {
    background-color: rgba($color-black, 1);
  }

  &--hidden {
    opacity: 0;
    visibility: hidden;
  }

  svg {
    fill: currentColor;
    width: 100%;
    height: 100%;
  }
}

// lazyload
.video-lazyload {
  opacity: 0;
}

.video-lazyloaded {
  transition: opacity 500ms $ease-fade;
  opacity: 1;
  backface-visibility: hidden; // Fixes position shift after transition
}
