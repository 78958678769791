.c-order-button {
  > * {
    position: fixed;
    z-index: 14;
    left: 1rem;
    bottom: 1.5rem;

    @media ($max-s) {
      display: none;
    }

    opacity: 0;
    visibility: hidden;
    transform: translateY(100%);
    transition: opacity 150ms $ease-fade 0ms, transform 500ms $ease-out 0ms,
      visibility 0ms linear 500ms;
  }

  &--sticky > * {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition-delay: 0ms, 0ms, 0ms;
  }
}
