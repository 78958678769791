// Less BEM used, since nav styles are fixed and not used elsewhere
.c-nav {
}

.c-nav__toggle {
  display: block;
  box-sizing: content-box;
  width: rem(26);
  height: rem(26);
  padding: rem(6);
  position: fixed;
  z-index: 12;
  top: rem(14);
  right: calc(var(--padding-x-base) - #{rem(10)});
  mix-blend-mode: difference;

  @media ($min-ms) {
    top: 1.625rem;
    width: rem(34);
    height: rem(34);
  }
}

.c-nav__wrapper {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100); // Custom --vh to fix iOS display
  background-color: $color-gray-bg;
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-left: var(--padding-x-base);
  padding-right: var(--padding-x-base);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  opacity: 0;
  visibility: hidden;
  //transition: opacity 150ms $ease-fade, visibility 0ms linear 150ms;

  .c-nav--active & {
    opacity: 1;
    visibility: visible;
    //transition-delay: 0ms, 0ms;
  }
}

.c-nav__lists {
  > * + * {
    margin-top: 2.375rem;
  }

  li {
    overflow: hidden;
  }

  li + li {
    margin-top: 0.5rem;
  }

  a {
    position: relative;
    display: inline-block;

    &::after {
      @include extended-clickarea($y: 4);
    }

    &:hover,
    &:focus,
    &.active {
      text-decoration: underline;
      text-decoration-thickness: 0.075em;
      text-underline-offset: 0.1em;
    }
  }
}

.c-nav__infotext {
  font-size: 1rem;
  color: $color-blue;
  font-weight: bold;
  white-space: nowrap;
  position: absolute;
  top: rem(-12);
  left: 92%;

  @media ($min-s) {
    top: 0;
    left: 102%;
  }
}

.c-nav__primary {
  font-size: rem(28);

  @media ($min-s) {
    font-size: rem(40);
  }
}

.c-nav__primary-anchor {
  position: relative;

  &::before {
    content: '';
    display: block;
    border-bottom: 2px solid $color-black;
    width: rem(43);
    margin-top: 1.375rem;
    margin-bottom: 1.375rem;
  }
}

.c-nav__secondary {
  font-size: rem(16);
  font-weight: bold;
}
