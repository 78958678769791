.c-artist-teaser {
  display: block;
  position: relative;

  .c-image__wrapper {
    // prettier-ignore
    transform: scale(calc(1 + 0.2 * var(--scale-factor, 0))) translate(var(--x, 0), var(--y, 0));
    transition: transform 0.8s $ease-out;
  }

  .c-caption {
    @include font-size-fluid($font-size-medium);
    color: $color-white;
    pointer-events: none;
    padding: 0;
    width: fit-content;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(var(--caption-x, 0), var(--caption-y, 0));
    transition: opacity 0.1s, visibility 0.1s, transform 0s ease-out 0.1s;
    visibility: hidden;
    opacity: 0;

    span {
      display: block;
      transform: translate(-50%, -50%);
    }
  }

  &--caption-visible .c-caption {
    // prettier-ignore
    transition: opacity 0.4s, visibility 0.4s, transform 0.8s $ease-out;
    visibility: visible;
    opacity: 1;
  }
}
