.spacing-text {
  // Default
  > * + * {
    margin-top: 1rem;
  }

  // Intro
  > .c-text--l + * {
    margin-top: 2.25rem;
  }

  > .c-text--m + * {
    margin-top: 2rem;
  }

  > .c-text--l + p {
    margin-top: 5rem;
  }

  > .c-text--m + p {
    margin-top: 4rem;
  }

  > .c-text--l + .c-text--l {
    margin-top: 2.5rem;
  }

  > .c-text--m + .c-text--m {
    margin-top: 2rem;
  }

  // Text
  > p + .c-text--l {
    margin-top: 5rem;
  }

  > .c-category + .c-text--l {
    margin-top: 2.5rem;
  }

  // Quote
  > .c-blockquote + * {
    margin-top: 3rem;
  }
  > * + .c-blockquote {
    margin-top: 3rem;
  }

  // Headlines
  > .c-hl--1 + * {
    margin-top: 3rem;
  }
  > .c-hl--2 + * {
    margin-top: 3rem;
  }
  > .c-hl--3 + * {
    margin-top: 2rem;
  }
  > .c-hl--4 + * {
    margin-top: 0.75rem;
  }

  > * + .c-hl--1 {
    margin-top: 5rem;
  }
  > * + .c-hl--2 {
    margin-top: 5rem;
  }
  > * + .c-hl--3 {
    margin-top: 3rem;
  }
  > * + .c-hl--4 {
    margin-top: 2rem;
  }

  .c-category + * {
    margin-top: 1.5rem;
  }

  // Button, Links
  > * + .c-button {
    margin-top: 2rem;
  }

  > * + .c-link--external,
  > * + .c-link--download {
    margin-top: 3rem;
  }

  > .c-link--external + *,
  > .c-link--download + * {
    margin-top: 3rem;
  }

  > .c-link--external + .c-link--external,
  > .c-link--download + .c-link--download {
    margin-top: 0.625rem;
  }

  > .c-image + .c-link--download,
  > .c-video + .c-link--download {
    margin-top: 1rem;
  }

  // Image, video
  > .c-image + *,
  > .c-video + * {
    margin-top: 4rem;
  }
  > * + .c-image,
  > * + .c-video {
    margin-top: 4rem;
  }
}

.spacing--teaser {
  > * + * {
    margin-top: 1rem;
  }

  > * + .c-hl--3 {
    margin-top: 2rem;
  }
}

.spacing-form {
  > * + * {
    margin-top: 1.5rem;
  }

  > .c-input + .c-checkbox {
    margin-top: 2.5rem;
  }
}
