// Base
.px-base {
  padding-left: var(--padding-x-base);
  padding-right: var(--padding-x-base);
}

@for $i from 1 through 10 {
  .pt-#{$i} {
    padding-top: var(--space-#{$i});
  }

  .pb-#{$i} {
    padding-bottom: var(--space-#{$i});
  }
}
