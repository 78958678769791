.l-teaser {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4rem 3rem;

  @media ($min-ms) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-row-gap: 6rem;
  }
}

.l-teaser__item {
  position: relative;

  > *:not(.c-image) {
    max-width: 21rem;
  }
}

.l-teaser__link {
  &::after {
    @include extended-clickarea(0, 0);
  }
}

.l-teaser__more {
  font-weight: bold;
  text-decoration: underline;

  .l-teaser__item:hover &,
  .l-teaser__item:focus-within & {
    text-decoration: none;
    color: $color-blue;
  }
}
