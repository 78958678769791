.l-canvas {
  height: 100vh;
  overflow: hidden;
  display: grid;

  > * {
    grid-area: 1 / 1 / 2 / 2;
  }

  canvas {
    position: relative;
    z-index: 2;
  }

  > .c-image {
    display: none;
    position: relative;
    z-index: 1;

    img {
      height: 100vh;
    }
  }

  &--fallback > .c-image {
    display: block;
  }
}

.l-canvas__hyperreal {
  font-size: 16vw;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  transform: translate(var(--text-x, 0px), var(--text-y, 0px));
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  justify-content: center;
  align-items: center;

  > span > span {
    opacity: 0;

    .no-js & {
      opacity: 1;
    }
  }
}
