.l-dummy-discount-form {
}

// Form
.l-dummy__form {
}

.l-dummy-hero,
.l-dummy__form-step-1,
.l-dummy__form-step-2 {
  &.hidden {
    display: none;
  }
}

.l-dummy__help {
  font-size: 1rem;
  color: #888;
  margin-top: 1.125rem;
}

.l-dummy__quantity {
  display: flex;
  align-items: center;
  gap: 1rem;

  label {
    width: 6rem;
  }

  select {
    width: 6rem;
  }
}

.l-dummy__format {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  gap: 0.5rem 1rem;

  @media ($max-s) {
    flex-wrap: wrap;
  }

  .c-input {
    flex-grow: 1;
  }
}

.l-dummy__format-label {
  width: 6rem;

  @media ($max-s) {
    width: 100%;
  }
}

.l-dummy__binding {
  margin-top: 3rem;

  select {
    margin-top: 0.5rem;
  }
}

.l-dummy__folded-binding {
  margin-top: 3rem;

  &.hidden {
    display: none;
  }
}

.l-dummy__folded-binding-radio {
  margin-top: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  input:checked + label path {
    fill: $color-black;
    stroke: $color-white;
  }

  label {
    cursor: pointer;
  }

  > div {
    label,
    svg {
      display: block;
      width: 100%;
    }
  }

  > div:nth-child(1) {
    width: 18%;
  }

  > div:nth-child(2) {
    width: 7%;
  }

  > div:nth-child(3) {
    width: 11%;
  }

  > div:nth-child(4) {
    width: 7%;
  }

  > div:nth-child(5) {
    width: 10%;
  }

  > div:nth-child(6) {
    width: 12%;
  }
}

.l-dummy__cover {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3rem;

  label {
    background-color: rgba($color-black, 0.03);
    color: rgba($color-black, 0.2);
    padding: 0.5rem 1rem;
    display: block;
    text-align: center;
    border: 1px solid transparent;
    min-width: 6rem;
    cursor: pointer;
  }

  input:checked + label {
    background: $color-white;
    color: $color-black;
    border: 1px solid $color-black;
  }
}

.l-dummy__cover-label {
  width: 100%;
  margin-bottom: 0.5rem;
}

.l-dummy__cover-paper {
  margin-top: 3rem;

  &.hidden {
    display: none;
  }

  label {
    display: block;
    margin-bottom: 0.5rem;
  }
}

.l-dummy__paper-input {
  display: grid;
  grid-template-columns: 5fr 3fr 2fr;
  gap: 1rem;

  @media ($max-s) {
    > div:first-child {
      grid-column: 1 / -1;
    }
    > div:last-child {
      grid-column: 2 / -1;
    }
  }
}

.l-dummy__insert-paper {
  margin-top: 3.5rem;

  label {
    display: block;
    margin-bottom: 0.5rem;
  }

  .l-dummy__paper-input {
    position: relative;

    &.hidden {
      display: none;
    }

    .l-dummy__insert-paper-remove {
      margin-top: 2rem;

      @media ($min-s) {
        position: absolute;
        left: calc(100% + 1rem);
        top: 1rem;
      }
    }
  }

  .l-dummy__paper-input + .l-dummy__paper-input {
    margin-top: 2.5rem;

    @media ($min-s) {
      margin-top: 1.5rem;
    }

    label {
      @media ($min-s) {
        display: none;
      }
    }
  }
}

.l-dummy-insert-paper-more {
  display: flex;
  align-items: center;
  gap: 1rem;

  &:disabled {
    opacity: 0.33;
    cursor: not-allowed;
  }
}

.l-dummy__insert-paper-max-count {
  color: red;

  &.hidden {
    display: none;
  }
}

// Checkout
.l-dummy-checkout-list {
  > div + div {
    margin-top: 0.5rem;
  }

  > div {
    display: grid;
    grid-template-columns: minmax(0, 7rem) 1fr;
    column-gap: 1rem;

    &.hidden {
      display: none;
    }
  }
}

// Discount
.l-dummy-discount-form__wrapper {
  display: flex;
  gap: 1rem;
  align-items: flex-end;

  .c-input {
    flex-grow: 1;
  }

  .c-button {
    padding: em(10 30 10);
  }
}

.l-dummy-discount-form__success {
  display: none;

  &.visible {
    display: block;
  }
}

.l-dummy-discount-form__error {
  display: none;

  &.visible {
    display: block;
  }
}

// Shipping order form
.l-dummy-order-form {
}

.l-dummy-order-form__hnpt {
  position: absolute;
  left: -9999px;
}

.l-dummy-order-form__text {
  .l-dummy-order-form--success & {
    display: none;
  }
}

.l-dummy-order-form__form {
  .l-dummy-order-form--success & {
    display: none;
  }
}

.l-dummy-order-form__error {
  color: rgb(179, 41, 41);
  display: none;

  &--visible {
    display: block;
  }
}

.l-dummy-order-form__success {
  display: none;

  .l-dummy-order-form--success & {
    display: block;
  }
}
