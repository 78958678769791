.c-raffle {
  position: relative;
  display: grid;

  > * {
    grid-area: 1 / 1 / 2 / 2;
  }

  input[type='radio'] {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;

    opacity: 0;
    pointer-events: none;
  }
}

$image-names: 'blue-shower', 'osaka', 'debra', 'experiments-organics',
  'table-of-contents', 'danedream';

.c-raffle__selection {
  z-index: 2;
}

.c-raffle__overlay {
  fill: $color-white;
  opacity: 0;
  transition: opacity 0.2s;
}

.c-raffle__check {
  fill: none;
  stroke: $color-black;
  stroke-width: 4;
  opacity: 0;
  transition: opacity 0.2s;
}

.c-raffle__label {
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  cursor: pointer;

  @each $name in $image-names {
    &--#{$name} {
      clip-path: url(##{$name}-clip);

      &:hover ~ svg ##{$name}-overlay,
      &:focus ~ svg ##{$name}-overlay {
        .c-raffle__overlay {
          opacity: 0.6;
        }

        .c-raffle__check {
          opacity: 0.3;
        }
      }
    }
  }
}

.c-raffle {
  @each $name in $image-names {
    input##{$name}[type='radio']:checked ~ svg ##{$name}-overlay {
      .c-raffle__overlay {
        opacity: 0.5;
      }

      .c-raffle__check {
        opacity: 1;
      }
    }
  }
}
