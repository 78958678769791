.l-article {
  > div {
    > *:not(.l-article__wide) {
      max-width: rem(640);
    }

    > .c-blockquote {
      margin-left: 1.5rem;

      @media ($min-ms) {
        margin-left: 2rem;
      }
    }
  }

  &--center {
    > div {
      text-align: center;

      > *:not(.l-article__wide) {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
