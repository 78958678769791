.c-image {
}

.c-image__wrapper {
  position: relative;

  &--small {
    width: rem(127);
    height: auto;
  }

  // Background: animate opacity instead of bg-color for better perf
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(128, 128, 128, 0.1); // Works on most backgrounds
    transition: opacity 1000ms $ease-fade, visibility 0ms linear 1000ms;

    .c-image--dark-bg & {
      background-color: rgba(0, 0, 0, 0.8);
    }
  }

  &--transparent::after {
    opacity: 0;
    visibility: hidden;
  }

  > img {
    object-fit: cover;
  }
}

// lazyload
.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  transition: opacity 500ms $ease-fade;
  opacity: 1;
  backface-visibility: hidden; // Fixes position shift after transition
}

.no-js .lazyload {
  display: none;
}
