.l-free-image-text {
  overflow: hidden;
}

.l-free-image-text__hl {
  text-align: center;
  font-weight: 700;
}

.l-free-image-text__hyperreal {
  font-size: 16vw;
  text-align: center;
  font-weight: 700;
  line-height: 1;
  z-index: 15; // always above images
  position: relative;
  pointer-events: none;
}

.l-free-image-text__flex {
  display: flex;
  align-items: flex-start;
}

.l-free-image-text__artist {
  &--artist1 {
    width: 55%;
    margin-top: 20%;
    margin-left: -4%;
  }

  &--artist2 {
    width: 38%;
    margin-top: -3.5%;
    margin-right: 4%;
    margin-left: auto;
  }

  &--artist3 {
    width: 35%;
    margin-top: 12%;
    margin-left: 7%;
  }

  &--artist4 {
    width: 55%;
    margin-top: 40%;
    margin-right: -3%;
    margin-left: auto;
  }

  &--artist5 {
    width: 55%;
    margin-top: -8%;
    margin-left: 7%;
  }

  &--artist6 {
    width: 40%;
    margin-top: 43%;
  }

  &--artist7 {
    width: 45%;
    margin-top: 13%;
    margin-left: auto;
    margin-right: 7%;
  }

  &--artist8 {
    width: 40%;
    margin-top: -9.5%;
    margin-left: 20%;
  }

  &--artist9 {
    width: 45.5%;
    margin-top: 10%;
  }

  &--artist10 {
    width: 66%;
    margin-right: 0;
    margin-left: auto;
    margin-top: 14%;
  }

  &--artist11 {
    width: 40%;
    margin-top: -8%;
    margin-left: 19%;
  }

  &--artist12 {
    width: 64%;
    margin-top: 93%;
    margin-right: -18%;
  }

  &--artist13 {
    width: 39%;
    margin-top: 18%;
    margin-left: auto;
    margin-right: 10%;
  }

  &--artist14 {
    width: 56%;
    margin-top: -5.5%;
    margin-left: 22.5%;
    margin-bottom: 5%;
  }

  // Desktop Layout
  @media ($min-ms) {
    &--artist1 {
      width: 41.25%;
      margin-top: 11%;
      margin-left: -2.25%;
    }

    &--artist2 {
      width: 26.25%;
      margin-top: -3.5%;
      margin-right: 7%;
      margin-left: auto;
    }

    &--artist3 {
      width: 26.25%;
      margin-left: 7%;
      margin-top: -3%;
    }

    &--artist4 {
      width: 41.25%;
      margin-top: 10%;
      margin-right: -3%;
      margin-left: auto;
    }

    &--artist5 {
      width: 41.25%;
      margin-top: -12%;
      margin-left: 33.25%;
    }

    &--artist6 {
      width: 26.25%;
      margin-top: 37%;
      margin-left: -8%;
    }

    &--artist7 {
      width: 41.25%;
      margin-top: 7%;
      margin-left: auto;
      margin-right: 7%;
    }

    &--artist8 {
      width: 26.25%;
      margin-top: -9.5%;
      margin-left: 14.5%;
    }

    &--artist9 {
      width: 26.25%;
      margin-top: 10%;
      margin-left: 7%;
    }

    &--artist10 {
      width: 41.25%;
      margin-right: -4%;
      margin-left: auto;
      margin-top: -35%;
    }

    &--artist11 {
      width: 26.25%;
      margin-top: -6%;
      margin-left: 48%;
    }

    &--artist12 {
      width: 47%;
      margin-top: 25%;
      margin-left: -3%;
    }

    &--artist13 {
      width: 26.25%;
      margin-top: 14.25%;
      margin-left: auto;
      margin-right: 5%;
      z-index: 13;
    }

    &--artist14 {
      width: 40%;
      margin-top: -5.5%;
      margin-left: 22.5%;
      margin-bottom: 10%;
      z-index: 12;
    }
  }
}

.l-free-image-text__text1,
.l-free-image-text__text2,
.l-free-image-text__text3 {
  @include font-size-fluid($font-size-medium);
  margin-left: var(--padding-x-base);
  margin-right: var(--padding-x-base);

  @media ($min-m) {
    width: 46%;
  }
}

.l-free-image-text__text1 {
  margin-top: 13%;

  @media ($min-m) {
    margin-top: 15.5%;
    margin-left: auto;
    margin-right: 7%;
  }
}

.l-free-image-text__text2 {
  margin-top: 13%;

  @media ($min-m) {
    margin-top: 14.25%;
    margin-left: 10.25%;
  }
}

.l-free-image-text__text3 {
  margin-top: 13%;

  @media ($min-m) {
    margin-top: 10%;
    margin-left: 40.5%;
  }
}
