.c-skip-link {
  border: 0;
  clip: rect(0 0 0 0);
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;

  &:focus {
    position: absolute;
    top: 3px;
    left: 3px;
    width: auto;
    height: auto;
    clip: auto;
    padding: 0.625em 1.25em;
    z-index: 20;
    background-color: $color-gray-light;
    text-decoration: none;
    box-shadow: $box-shadow-default;
  }
}
