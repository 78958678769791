.bg-white {
  background-color: $color-white;
}

.bg-gray-dark {
  background-color: $color-gray-dark;
}

.bg-gray-light {
  background-color: $color-gray-bg;
}
