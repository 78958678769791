.c-input {
  &--custom-country {
    display: none;
  }

  fieldset:disabled & {
    opacity: 0.5;
  }
}

.c-input__label {
  margin-bottom: em(5);
  @include font-size-fluid($font-size-small);

  &--no-select {
    user-select: none;
  }

  fieldset:disabled & {
    opacity: 0.5;
  }
}

.c-input__input,
.c-input__select {
  padding: rem(11 14 9);
  border: none;
  border-bottom: 2px solid $color-black;
  color: $color-black;
  background-color: rgba($color-black, 0.03);
  box-shadow: 0 0 10px inset rgba($color-black, 0.04);
  transition: background 150ms $ease-fade, box-shadow 150ms $ease-fade;

  @media ($min-ms) {
    padding: rem(11 18 9);
  }

  &:focus {
    outline: 3px solid transparent;
    outline-offset: -3px;
    background-color: $color-white;
    box-shadow: $box-shadow-default;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.c-input__input {
  &::placeholder {
    color: rgba($color-black, 0.2);
    opacity: 1;
  }

  // Fixes extra padding left in Safari for type="search"
  &[type='search']::-webkit-search-decoration {
    appearance: none;
  }
}

.c-input__select {
  background-repeat: no-repeat;
  background-position: right 1em top 1em;
  background-size: em(22 * 0.7 13 * 0.7);
  background-image: url("data:image/svg+xml;charset=uft8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 13'%3E%3Cpolyline fill='none' stroke='currentColor' stroke-width='3' points='1,1 11,11 21,1'/%3E%3C/svg%3E");

  // "Placeholder" color
  &:invalid {
    color: rgba($color-black, 0.2);
  }

  // Reset Firefox option color
  option {
    color: $color-black;
  }

  // Remove arrow in IE
  &::-ms-expand {
    display: none;
  }

  // Undo Firefox focus ring
  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $color-black;
  }
}

// Range mixins
@mixin range-slider-thumb {
  cursor: pointer;
  display: block;
  width: rem(18);
  height: rem(18);
  border: 0;
  border-radius: 50%;
  background-color: $color-black;
  box-shadow: 0 0 0 transparent;
  transition: transform 200ms $ease-out, box-shadow 150ms $ease-fade;
  transform-origin: center;
}

@mixin range-slider-thumb-focus {
  background-color: $color-white;
  border: 2px solid $color-black;
  box-shadow: $box-shadow-default;
  transform: scale(1.1);
}

@mixin range-slider-track {
  width: 100%;
  height: 2px;
  background-color: $color-black;
}

.c-input__range {
  -webkit-appearance: none;
  width: 100%;
  background: transparent;
  height: rem(20);

  // Thumb
  &::-webkit-slider-thumb {
    @include range-slider-thumb;
    -webkit-appearance: none;
    margin-top: rem(-8);
  }

  &::-moz-range-thumb {
    @include range-slider-thumb;
  }

  &::-ms-thumb {
    margin-top: 0;
  }

  // Track
  &::-webkit-slider-runnable-track {
    @include range-slider-track;
  }

  &::-moz-range-track {
    @include range-slider-track;
  }

  &::-ms-track {
    @include range-slider-track;
  }

  &:focus {
    outline: 0;
  }

  // Only show on keyboard focus
  &:focus-visible {
    &::-webkit-slider-thumb {
      @include range-slider-thumb-focus;
    }

    &::-moz-range-thumb {
      @include range-slider-thumb-focus;
    }
  }

  &::-moz-focus-outer {
    border: 0;
  }
}
