.c-pagination {
  display: flex;
  justify-content: space-between;
  margin: 6.5rem 1.5rem 0;
}

.c-pagination__prev,
.c-pagination__next {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, min-content));
  grid-gap: 1rem;
  align-items: center;

  @media ($min-ms) {
    grid-gap: 2rem;
  }

  svg {
    width: rem(44);
    height: rem(27);
    fill: none;
    stroke: currentColor;
    stroke-width: 1.5;
    padding: 0.25rem;
    box-sizing: content-box;
    transition: stroke 150ms $ease-fade;
  }

  &:hover,
  &:focus {
    svg {
      stroke: $color-blue;
    }

    @media (hover: hover) and (pointer: fine) {
      .c-pagination__preview-image {
        opacity: 1;
      }
    }
  }
}

.c-pagination__prev {
  svg {
    transform: rotate(180deg);
  }
}

.c-pagination__next {
  margin-left: auto;
}

.c-pagination__preview-image {
  @media (hover: hover) and (pointer: fine) {
    opacity: 0;
    transition: opacity 150ms $ease-fade;
  }

  .c-image {
    height: 3rem;

    * {
      height: 100%;
      width: auto;
    }
  }

  @media ($min-ms) {
    .c-image {
      height: 4rem;

      * {
        height: 100%;
        width: auto;
      }
    }
  }
}
