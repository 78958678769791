.l-text-image-layout {
  display: grid;
  grid-gap: 4rem;

  @media ($min-ms) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 8rem;
    align-items: center;
  }
}
