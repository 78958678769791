.c-legacy-warning {
  position: fixed;
  z-index: 100;
  top: 6.5rem;
  width: 100%;
  padding: 2rem;
  text-align: center;
  background-color: $color-white;
  border: 4px solid $color-black;
  box-shadow: $box-shadow-default;
}
