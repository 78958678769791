// Less BEM used, since styles are not used elsewhere
.l-text-images-scroll-layout {
  display: grid;
  grid-gap: 4rem;

  @media ($min-ms) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 6rem;
    align-items: stretch;
  }
}

.l-text-images-scroll-layout__text {
  @media ($min-ms) {
    position: sticky;
    top: 0;
    min-height: 100vh;
    display: flex;
    align-items: center;
  }
}

.l-text-images-scroll-layout__images {
  @media ($min-ms) {
    // too difficult to calculate in css, so use js
    padding: calc((100vh - var(--height, 80vh)) / 2) 0;
  }

  > * {
    position: sticky;
    top: 4rem;

    @media ($min-ms) {
      width: 65vmin;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
      top: calc((100vh - var(--height, 80vh)) / 2);
    }
  }
}
