@charset "UTF-8";
:root {
  --padding-x-base: 1rem;
  --space-base: 0.75rem;
  --space-1: calc(1 * var(--space-base));
  --space-2: calc(2 * var(--space-base));
  --space-3: calc(3 * var(--space-base));
  --space-4: calc(4 * var(--space-base));
  --space-5: calc(5 * var(--space-base));
  --space-6: calc(6 * var(--space-base));
  --space-7: calc(7 * var(--space-base));
  --space-8: calc(8 * var(--space-base));
  --space-9: calc(9 * var(--space-base));
  --space-10: calc(10 * var(--space-base));
}
@media (min-width: 30em) {
  :root {
    --padding-x-base: 3rem;
  }
}
@media (min-width: 80em) {
  :root {
    --padding-x-base: 5rem;
  }
}
@media (min-width: 45em) {
  :root {
    --space-base: 1rem;
  }
}

@font-face {
  font-family: "Munken Sans Web";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("../fnt/munken-sans-regular.woff2") format("woff2"), url("../fnt/munken-sans-regular.woff") format("woff");
}
@font-face {
  font-family: "Munken Sans Web";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("../fnt/munken-sans-medium.woff2") format("woff2"), url("../fnt/munken-sans-medium.woff") format("woff");
}
@font-face {
  font-family: "Munken Sans Web";
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  src: url("../fnt/munken-sans-bold.woff2") format("woff2"), url("../fnt/munken-sans-bold.woff") format("woff");
}
html {
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-wrap: break-word;
  overflow-wrap: break-word;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: inherit;
  font-size: inherit;
}

strong {
  font-weight: 900;
}

ul,
ol,
dl,
dt,
dd,
p {
  margin: 0;
  padding: 0;
}

ul li,
ol li {
  padding: 0;
  list-style-type: none;
}

blockquote,
figure {
  margin: 0;
}

figcaption {
  font-weight: inherit;
}

img,
video,
picture {
  display: block;
  width: 100%;
  height: auto;
}

svg {
  display: block;
}

iframe {
  border: none;
}

address {
  font-style: normal;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  border: 0;
  border-radius: 0;
  color: inherit;
  background: none;
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  text-transform: inherit;
  cursor: pointer;
}

input {
  font-family: inherit;
  font-size: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  text-transform: none;
  appearance: none;
  display: block;
  width: 100%;
  margin: 0;
  border-radius: 0;
}

select {
  font-family: inherit;
  font-size: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  text-transform: none;
  appearance: none;
  display: block;
  width: 100%;
  margin: 0;
  border-radius: 0;
}

fieldset {
  padding: 0;
  margin: 0;
  border: none;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: block;
  width: 100%;
  padding: 0;
  white-space: normal;
}

a:focus:not(:focus-visible),
button:focus:not(:focus-visible) {
  outline-color: transparent;
  outline-style: solid;
}

@media (prefers-reduced-motion: reduce) {
  *,
*::before,
*::after {
    animation-duration: 0.001s !important;
    duration-default: 0.001s !important;
    transition-delay: 0s !important;
  }
}

::selection {
  background-color: black;
  color: white;
}

html {
  font-family: "Munken Sans Web", -system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 1em;
  color: black;
  background-color: white;
}

body {
  font-size: 1.125rem;
  line-height: 1.5;
  letter-spacing: 0;
  background-color: white;
  min-height: 100vh;
  overflow-y: scroll;
  position: relative;
  display: flex;
  flex-direction: column;
}
@media (min-width: 30em) {
  body {
    font-size: calc(0.25vw + 1.05rem);
  }
}
@media (min-width: 80em) {
  body {
    font-size: 1.25rem;
  }
}
@media (max-width: 22.4375em) {
  body {
    hyphens: auto;
  }
}

main {
  flex-grow: 1;
}

.a11y-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
}

.color-white {
  color: white;
}

.bg-white {
  background-color: white;
}

.bg-gray-dark {
  background-color: #181818;
}

.bg-gray-light {
  background-color: #f7f7f7;
}

.spacing-text > * + * {
  margin-top: 1rem;
}
.spacing-text > .c-text--l + * {
  margin-top: 2.25rem;
}
.spacing-text > .c-text--m + * {
  margin-top: 2rem;
}
.spacing-text > .c-text--l + p {
  margin-top: 5rem;
}
.spacing-text > .c-text--m + p {
  margin-top: 4rem;
}
.spacing-text > .c-text--l + .c-text--l {
  margin-top: 2.5rem;
}
.spacing-text > .c-text--m + .c-text--m {
  margin-top: 2rem;
}
.spacing-text > p + .c-text--l {
  margin-top: 5rem;
}
.spacing-text > .c-category + .c-text--l {
  margin-top: 2.5rem;
}
.spacing-text > .c-blockquote + * {
  margin-top: 3rem;
}
.spacing-text > * + .c-blockquote {
  margin-top: 3rem;
}
.spacing-text > .c-hl--1 + * {
  margin-top: 3rem;
}
.spacing-text > .c-hl--2 + * {
  margin-top: 3rem;
}
.spacing-text > .c-hl--3 + * {
  margin-top: 2rem;
}
.spacing-text > .c-hl--4 + * {
  margin-top: 0.75rem;
}
.spacing-text > * + .c-hl--1 {
  margin-top: 5rem;
}
.spacing-text > * + .c-hl--2 {
  margin-top: 5rem;
}
.spacing-text > * + .c-hl--3 {
  margin-top: 3rem;
}
.spacing-text > * + .c-hl--4 {
  margin-top: 2rem;
}
.spacing-text .c-category + * {
  margin-top: 1.5rem;
}
.spacing-text > * + .c-button {
  margin-top: 2rem;
}
.spacing-text > * + .c-link--external,
.spacing-text > * + .c-link--download {
  margin-top: 3rem;
}
.spacing-text > .c-link--external + *,
.spacing-text > .c-link--download + * {
  margin-top: 3rem;
}
.spacing-text > .c-link--external + .c-link--external,
.spacing-text > .c-link--download + .c-link--download {
  margin-top: 0.625rem;
}
.spacing-text > .c-image + .c-link--download,
.spacing-text > .c-video + .c-link--download {
  margin-top: 1rem;
}
.spacing-text > .c-image + *,
.spacing-text > .c-video + * {
  margin-top: 4rem;
}
.spacing-text > * + .c-image,
.spacing-text > * + .c-video {
  margin-top: 4rem;
}

.spacing--teaser > * + * {
  margin-top: 1rem;
}
.spacing--teaser > * + .c-hl--3 {
  margin-top: 2rem;
}

.spacing-form > * + * {
  margin-top: 1.5rem;
}
.spacing-form > .c-input + .c-checkbox {
  margin-top: 2.5rem;
}

.px-base {
  padding-left: var(--padding-x-base);
  padding-right: var(--padding-x-base);
}

.pt-1 {
  padding-top: var(--space-1);
}

.pb-1 {
  padding-bottom: var(--space-1);
}

.pt-2 {
  padding-top: var(--space-2);
}

.pb-2 {
  padding-bottom: var(--space-2);
}

.pt-3 {
  padding-top: var(--space-3);
}

.pb-3 {
  padding-bottom: var(--space-3);
}

.pt-4 {
  padding-top: var(--space-4);
}

.pb-4 {
  padding-bottom: var(--space-4);
}

.pt-5 {
  padding-top: var(--space-5);
}

.pb-5 {
  padding-bottom: var(--space-5);
}

.pt-6 {
  padding-top: var(--space-6);
}

.pb-6 {
  padding-bottom: var(--space-6);
}

.pt-7 {
  padding-top: var(--space-7);
}

.pb-7 {
  padding-bottom: var(--space-7);
}

.pt-8 {
  padding-top: var(--space-8);
}

.pb-8 {
  padding-bottom: var(--space-8);
}

.pt-9 {
  padding-top: var(--space-9);
}

.pb-9 {
  padding-bottom: var(--space-9);
}

.pt-10 {
  padding-top: var(--space-10);
}

.pb-10 {
  padding-bottom: var(--space-10);
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mt-base-large {
  margin-top: 5.375rem;
}
@media (min-width: 45em) {
  .mt-base-large {
    margin-top: 10rem;
  }
}

.mt-base-small {
  margin-top: 2.875rem;
}
@media (min-width: 45em) {
  .mt-base-small {
    margin-top: 2.5rem;
  }
}

@media (min-width: 45em) {
  .mt-base-special {
    margin-top: -5rem;
  }
}

.mt-base-canvas {
  margin-top: -4rem;
}
@media (min-width: 45em) {
  .mt-base-canvas {
    margin-top: -5rem;
  }
}

.mt-1 {
  margin-top: var(--space-1);
}

.mb-1 {
  margin-bottom: var(--space-1);
}

.mt-2 {
  margin-top: var(--space-2);
}

.mb-2 {
  margin-bottom: var(--space-2);
}

.mt-3 {
  margin-top: var(--space-3);
}

.mb-3 {
  margin-bottom: var(--space-3);
}

.mt-4 {
  margin-top: var(--space-4);
}

.mb-4 {
  margin-bottom: var(--space-4);
}

.mt-5 {
  margin-top: var(--space-5);
}

.mb-5 {
  margin-bottom: var(--space-5);
}

.mt-6 {
  margin-top: var(--space-6);
}

.mb-6 {
  margin-bottom: var(--space-6);
}

.mt-7 {
  margin-top: var(--space-7);
}

.mb-7 {
  margin-bottom: var(--space-7);
}

.mt-8 {
  margin-top: var(--space-8);
}

.mb-8 {
  margin-bottom: var(--space-8);
}

.mt-9 {
  margin-top: var(--space-9);
}

.mb-9 {
  margin-bottom: var(--space-9);
}

.mt-10 {
  margin-top: var(--space-10);
}

.mb-10 {
  margin-bottom: var(--space-10);
}

.font-bold {
  font-weight: 700;
}

.mw-medium {
  max-width: 51rem;
}

.mw-narrow {
  max-width: 36.25rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.c-skip-link {
  border: 0;
  clip: rect(0 0 0 0);
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
}
.c-skip-link:focus {
  position: absolute;
  top: 3px;
  left: 3px;
  width: auto;
  height: auto;
  clip: auto;
  padding: 0.625em 1.25em;
  z-index: 20;
  background-color: #e6e6e6;
  text-decoration: none;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1), 3px 2px 12px rgba(0, 0, 0, 0.15);
}

.c-nav-icon {
  width: 100%;
  height: auto;
  display: block;
  fill: none;
  pointer-events: none;
  stroke-width: 2;
  stroke: white;
}

.c-nav-icon__line {
  stroke-dasharray: 24;
  stroke-dashoffset: 0;
  opacity: 1;
  transition: stroke-dashoffset 300ms cubic-bezier(0.23, 1, 0.32, 1), opacity 100ms cubic-bezier(0.25, 0, 0.25, 1);
  transition-delay: 200ms, 200ms;
}
.c-nav-icon__line:nth-child(2) {
  transition-delay: 300ms, 300ms;
}
.c-nav-icon__line:nth-child(3) {
  transition-delay: 400ms, 400ms;
}

.c-nav-icon--active .c-nav-icon__line {
  stroke-dashoffset: 24;
  opacity: 0;
  transition-delay: 0ms, 200ms;
}
.c-nav-icon--active .c-nav-icon__line:nth-child(1) {
  transition-delay: 200ms, 400ms;
}
.c-nav-icon--active .c-nav-icon__line:nth-child(2) {
  transition-delay: 100ms, 300ms;
}

.c-nav-icon__cross {
  stroke-dasharray: 33.9411239624;
  stroke-dashoffset: 33.9411239624;
  opacity: 0;
  transition: stroke-dashoffset 300ms cubic-bezier(0.23, 1, 0.32, 1), opacity 100ms cubic-bezier(0.25, 0, 0.25, 1);
  transition-delay: 0ms, 200ms;
}
.c-nav-icon__cross:nth-child(5) {
  transition-delay: 100ms, 300ms;
}

.c-nav-icon--active .c-nav-icon__cross {
  stroke-dashoffset: 0;
  opacity: 1;
  transition-delay: 300ms, 300ms;
}
.c-nav-icon--active .c-nav-icon__cross:nth-child(5) {
  transition-delay: 400ms, 400ms;
}

.c-nav__toggle {
  display: block;
  box-sizing: content-box;
  width: 1.625rem;
  height: 1.625rem;
  padding: 0.375rem;
  position: fixed;
  z-index: 12;
  top: 0.875rem;
  right: calc(var(--padding-x-base) - 0.625rem);
  mix-blend-mode: difference;
}
@media (min-width: 45em) {
  .c-nav__toggle {
    top: 1.625rem;
    width: 2.125rem;
    height: 2.125rem;
  }
}

.c-nav__wrapper {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  background-color: #f7f7f7;
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-left: var(--padding-x-base);
  padding-right: var(--padding-x-base);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
}
.c-nav--active .c-nav__wrapper {
  opacity: 1;
  visibility: visible;
}

.c-nav__lists > * + * {
  margin-top: 2.375rem;
}
.c-nav__lists li {
  overflow: hidden;
}
.c-nav__lists li + li {
  margin-top: 0.5rem;
}
.c-nav__lists a {
  position: relative;
  display: inline-block;
}
.c-nav__lists a::after {
  content: "";
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  position: absolute;
  padding: 0.25rem 0;
  top: -0.25rem;
  left: 0;
}
.c-nav__lists a:hover, .c-nav__lists a:focus, .c-nav__lists a.active {
  text-decoration: underline;
  text-decoration-thickness: 0.075em;
  text-underline-offset: 0.1em;
}

.c-nav__infotext {
  font-size: 1rem;
  color: #143c9c;
  font-weight: bold;
  white-space: nowrap;
  position: absolute;
  top: -0.75rem;
  left: 92%;
}
@media (min-width: 30em) {
  .c-nav__infotext {
    top: 0;
    left: 102%;
  }
}

.c-nav__primary {
  font-size: 1.75rem;
}
@media (min-width: 30em) {
  .c-nav__primary {
    font-size: 2.5rem;
  }
}

.c-nav__primary-anchor {
  position: relative;
}
.c-nav__primary-anchor::before {
  content: "";
  display: block;
  border-bottom: 2px solid black;
  width: 2.6875rem;
  margin-top: 1.375rem;
  margin-bottom: 1.375rem;
}

.c-nav__secondary {
  font-size: 1rem;
  font-weight: bold;
}

.c-cookie-consent {
  position: fixed;
  z-index: 20;
  bottom: 0;
  left: 0;
  width: 100%;
  font-size: 0.9375rem;
  padding-left: var(--padding-x-base);
  padding-right: var(--padding-x-base);
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: white;
  color: black;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1), 3px 2px 12px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 300ms cubic-bezier(0.25, 0, 0.25, 1) 0ms, visibility 0ms linear 300ms;
}
@media (min-width: 30em) {
  .c-cookie-consent {
    font-size: calc(0.125vw + 0.9rem);
  }
}
@media (min-width: 80em) {
  .c-cookie-consent {
    font-size: 1rem;
  }
}
@media (min-width: 45em) {
  .c-cookie-consent {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}
.c-cookie-consent--visible {
  opacity: 1;
  visibility: visible;
  transition-delay: 0ms, 0ms;
}

.c-cookie-consent__text {
  margin-left: auto;
  margin-right: 2rem;
}
@media (max-width: 44.9375em) {
  .c-cookie-consent__text {
    flex-basis: 100%;
    margin-bottom: 1rem;
  }
}

.c-cookie-consent__button + .c-cookie-consent__button {
  margin-left: 0.75rem;
}
.c-cookie-consent__button:last-child {
  margin-right: auto;
}

.c-link {
  transition: color 150ms cubic-bezier(0.25, 0, 0.25, 1);
}
.c-link:hover, .c-link:focus {
  color: #143c9c;
}
.c-link--underline, .c-link--subtle:hover, .c-link--subtle:focus {
  text-decoration: underline;
  text-decoration-thickness: 0.0625em;
  text-underline-offset: 2px;
}
.c-link--underline:hover, .c-link--underline:focus {
  text-decoration: none;
}
.c-link--extended {
  position: relative;
}
.c-link--extended::after {
  content: "";
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  position: absolute;
  padding: 0.375rem 0;
  top: -0.375rem;
  left: 0;
}
.c-link--bold {
  font-weight: bold;
}
.c-link--external {
  display: table;
}
.c-link--external i {
  font-style: normal;
  display: inline-block;
}
.c-link--download {
  display: table;
  position: relative;
}
.c-link--download svg {
  width: 0.875em;
  height: 1.0625em;
  fill: currentColor;
  display: inline-block;
  vertical-align: sub;
  margin-right: 0.375em;
}
.c-link--download span::after {
  content: " (" attr(data-type) ")";
  text-transform: uppercase;
}

.c-hl--1 {
  font-size: 2rem;
  line-height: 1.2;
  font-weight: 700;
}
@media (min-width: 30em) {
  .c-hl--1 {
    font-size: calc(1vw + 1.7rem);
  }
}
@media (min-width: 80em) {
  .c-hl--1 {
    font-size: 2.5rem;
  }
}

.c-hl--2 {
  font-size: 1.5rem;
  line-height: 1.35;
  font-weight: 700;
}
@media (min-width: 30em) {
  .c-hl--2 {
    font-size: calc(0.75vw + 1.275rem);
  }
}
@media (min-width: 80em) {
  .c-hl--2 {
    font-size: 1.875rem;
  }
}

.c-hl--3 {
  font-size: 1.25rem;
  line-height: 1.35;
  font-weight: 700;
}
@media (min-width: 30em) {
  .c-hl--3 {
    font-size: calc(0.5vw + 1.1rem);
  }
}
@media (min-width: 80em) {
  .c-hl--3 {
    font-size: 1.5rem;
  }
}

.c-hl--4 {
  font-weight: 900;
}

.c-list {
  padding-left: 1.25em;
}
.c-list > li {
  position: relative;
}
.c-list > li::before {
  position: absolute;
}
.c-list > li + li {
  margin-top: 0.25em;
}
.c-list--unordered > li::before {
  content: "";
  width: 5px;
  height: 5px;
  background-color: #000;
  border-radius: 50%;
  left: -1em;
  top: 0.6875em;
}
.c-list--ordered {
  list-style-type: none;
  counter-reset: ordered-list;
}
.c-list--ordered > li::before {
  counter-increment: ordered-list;
  content: counter(ordered-list) ".";
  left: -1.25em;
}

.c-image__wrapper {
  position: relative;
}
.c-image__wrapper--small {
  width: 7.9375rem;
  height: auto;
}
.c-image__wrapper::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(128, 128, 128, 0.1);
  transition: opacity 1000ms cubic-bezier(0.25, 0, 0.25, 1), visibility 0ms linear 1000ms;
}
.c-image--dark-bg .c-image__wrapper::after {
  background-color: rgba(0, 0, 0, 0.8);
}
.c-image__wrapper--transparent::after {
  opacity: 0;
  visibility: hidden;
}
.c-image__wrapper > img {
  object-fit: cover;
}

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  transition: opacity 500ms cubic-bezier(0.25, 0, 0.25, 1);
  opacity: 1;
  backface-visibility: hidden;
}

.no-js .lazyload {
  display: none;
}

.c-video__wrapper {
  display: grid;
  position: relative;
}
.c-video__wrapper > * {
  grid-area: 1/1/2/2;
}
.c-video__wrapper::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(128, 128, 128, 0.1);
  transition: opacity 1000ms cubic-bezier(0.25, 0, 0.25, 1), visibility 0ms linear 1000ms;
}
.c-video--dark-bg .c-video__wrapper::after {
  background-color: rgba(0, 0, 0, 0.8);
}
.c-video__wrapper--transparent::after {
  opacity: 0;
  visibility: hidden;
}
.c-video__wrapper svg {
  width: 100%;
  height: auto;
}
.c-video__unmute {
  width: 3rem;
  height: 3rem;
  padding: 0.5rem;
  position: absolute;
  z-index: 1;
  top: 1rem;
  left: 1rem;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 500ms cubic-bezier(0.25, 0, 0.25, 1), visibility 0ms linear, background-color 150ms cubic-bezier(0.25, 0, 0.25, 1);
  opacity: 1;
  visibility: visible;
}
@media (min-width: 45em) {
  .c-video__unmute {
    top: 1.5rem;
    left: 1.5rem;
  }
}
.c-video__unmute:hover, .c-video__unmute:focus {
  background-color: black;
}
.c-video__unmute--hidden {
  opacity: 0;
  visibility: hidden;
}
.c-video__unmute svg {
  fill: currentColor;
  width: 100%;
  height: 100%;
}

.video-lazyload {
  opacity: 0;
}

.video-lazyloaded {
  transition: opacity 500ms cubic-bezier(0.25, 0, 0.25, 1);
  opacity: 1;
  backface-visibility: hidden;
}

.c-caption {
  font-size: 0.9375rem;
}
@media (min-width: 30em) {
  .c-caption {
    font-size: calc(0.125vw + 0.9rem);
  }
}
@media (min-width: 80em) {
  .c-caption {
    font-size: 1rem;
  }
}
.c-caption--padding-top {
  padding-top: 0.5rem;
}
@media (min-width: 45em) {
  .c-caption--padding-top {
    padding-top: 0.75rem;
  }
}
.c-caption--padding-x {
  padding-left: var(--padding-x-base);
  padding-right: var(--padding-x-base);
}

.c-text--l {
  font-size: 1.5rem;
  line-height: 1.35;
}
@media (min-width: 30em) {
  .c-text--l {
    font-size: calc(0.75vw + 1.275rem);
  }
}
@media (min-width: 80em) {
  .c-text--l {
    font-size: 1.875rem;
  }
}

.c-text--m {
  font-size: 1.25rem;
}
@media (min-width: 30em) {
  .c-text--m {
    font-size: calc(0.5vw + 1.1rem);
  }
}
@media (min-width: 80em) {
  .c-text--m {
    font-size: 1.5rem;
  }
}

.c-text--s {
  font-size: 0.9375rem;
}
@media (min-width: 30em) {
  .c-text--s {
    font-size: calc(0.125vw + 0.9rem);
  }
}
@media (min-width: 80em) {
  .c-text--s {
    font-size: 1rem;
  }
}

.c-blockquote {
  quotes: "“" "”";
}
.c-blockquote > p::before {
  content: open-quote;
}
.c-blockquote > p::after {
  content: close-quote;
}
.c-blockquote > p {
  font-size: 1.25rem;
}
@media (min-width: 30em) {
  .c-blockquote > p {
    font-size: calc(0.5vw + 1.1rem);
  }
}
@media (min-width: 80em) {
  .c-blockquote > p {
    font-size: 1.5rem;
  }
}
.c-blockquote footer {
  padding-top: 1.25em;
  font-size: 0.9375rem;
  font-weight: 900;
}
@media (min-width: 30em) {
  .c-blockquote footer {
    font-size: calc(0.125vw + 0.9rem);
  }
}
@media (min-width: 80em) {
  .c-blockquote footer {
    font-size: 1rem;
  }
}

.c-category {
  font-weight: bold;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.c-input--custom-country {
  display: none;
}
fieldset:disabled .c-input {
  opacity: 0.5;
}

.c-input__label {
  margin-bottom: 0.3125em;
  font-size: 0.9375rem;
}
@media (min-width: 30em) {
  .c-input__label {
    font-size: calc(0.125vw + 0.9rem);
  }
}
@media (min-width: 80em) {
  .c-input__label {
    font-size: 1rem;
  }
}
.c-input__label--no-select {
  user-select: none;
}
fieldset:disabled .c-input__label {
  opacity: 0.5;
}

.c-input__input,
.c-input__select {
  padding: 0.6875rem 0.875rem 0.5625rem;
  border: none;
  border-bottom: 2px solid black;
  color: black;
  background-color: rgba(0, 0, 0, 0.03);
  box-shadow: 0 0 10px inset rgba(0, 0, 0, 0.04);
  transition: background 150ms cubic-bezier(0.25, 0, 0.25, 1), box-shadow 150ms cubic-bezier(0.25, 0, 0.25, 1);
}
@media (min-width: 45em) {
  .c-input__input,
.c-input__select {
    padding: 0.6875rem 1.125rem 0.5625rem;
  }
}
.c-input__input:focus,
.c-input__select:focus {
  outline: 3px solid transparent;
  outline-offset: -3px;
  background-color: white;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1), 3px 2px 12px rgba(0, 0, 0, 0.15);
}
.c-input__input:disabled,
.c-input__select:disabled {
  opacity: 0.5;
}

.c-input__input::placeholder {
  color: rgba(0, 0, 0, 0.2);
  opacity: 1;
}
.c-input__input[type=search]::-webkit-search-decoration {
  appearance: none;
}

.c-input__select {
  background-repeat: no-repeat;
  background-position: right 1em top 1em;
  background-size: 0.9375em 0.5625em;
  background-image: url("data:image/svg+xml;charset=uft8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 13'%3E%3Cpolyline fill='none' stroke='currentColor' stroke-width='3' points='1,1 11,11 21,1'/%3E%3C/svg%3E");
}
.c-input__select:invalid {
  color: rgba(0, 0, 0, 0.2);
}
.c-input__select option {
  color: black;
}
.c-input__select::-ms-expand {
  display: none;
}
.c-input__select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 black;
}

.c-input__range {
  -webkit-appearance: none;
  width: 100%;
  background: transparent;
  height: 1.25rem;
}
.c-input__range::-webkit-slider-thumb {
  cursor: pointer;
  display: block;
  width: 1.125rem;
  height: 1.125rem;
  border: 0;
  border-radius: 50%;
  background-color: black;
  box-shadow: 0 0 0 transparent;
  transition: transform 200ms cubic-bezier(0.23, 1, 0.32, 1), box-shadow 150ms cubic-bezier(0.25, 0, 0.25, 1);
  transform-origin: center;
  -webkit-appearance: none;
  margin-top: -0.5rem;
}
.c-input__range::-moz-range-thumb {
  cursor: pointer;
  display: block;
  width: 1.125rem;
  height: 1.125rem;
  border: 0;
  border-radius: 50%;
  background-color: black;
  box-shadow: 0 0 0 transparent;
  transition: transform 200ms cubic-bezier(0.23, 1, 0.32, 1), box-shadow 150ms cubic-bezier(0.25, 0, 0.25, 1);
  transform-origin: center;
}
.c-input__range::-ms-thumb {
  margin-top: 0;
}
.c-input__range::-webkit-slider-runnable-track {
  width: 100%;
  height: 2px;
  background-color: black;
}
.c-input__range::-moz-range-track {
  width: 100%;
  height: 2px;
  background-color: black;
}
.c-input__range::-ms-track {
  width: 100%;
  height: 2px;
  background-color: black;
}
.c-input__range:focus {
  outline: 0;
}
.c-input__range:focus-visible::-webkit-slider-thumb {
  background-color: white;
  border: 2px solid black;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1), 3px 2px 12px rgba(0, 0, 0, 0.15);
  transform: scale(1.1);
}
.c-input__range:focus-visible::-moz-range-thumb {
  background-color: white;
  border: 2px solid black;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1), 3px 2px 12px rgba(0, 0, 0, 0.15);
  transform: scale(1.1);
}
.c-input__range::-moz-focus-outer {
  border: 0;
}

.c-checkbox {
  position: relative;
}
fieldset:disabled .c-checkbox {
  opacity: 0.25;
}

.c-checkbox__input {
  width: 1.3125em;
  height: 1.3125em;
  position: absolute;
  left: 0;
  top: 0.125em;
  opacity: 0;
  pointer-events: none;
}

.c-checkbox__label {
  display: inline-flex;
  align-items: flex-start;
  user-select: none;
  cursor: pointer;
}

.c-checkbox__label::before {
  content: "";
  display: inline-block;
  flex-shrink: 0;
  width: 1.3125em;
  height: 1.3125em;
  position: relative;
  top: 0.125em;
  margin-right: 0.625em;
  background-color: rgba(0, 0, 0, 0.03);
  border: 2px solid black;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 74%;
  outline-offset: -2px;
  outline: 2px solid transparent;
  box-shadow: 0 0 0 transparent;
  transition: background 150ms cubic-bezier(0.25, 0, 0.25, 1), box-shadow 150ms cubic-bezier(0.25, 0, 0.25, 1);
}
.c-checkbox__input:focus-visible + .c-checkbox__label::before, .c-checkbox__input:focus:invalid + .c-checkbox__label::before {
  background-color: white;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1), 3px 2px 12px rgba(0, 0, 0, 0.15);
}
.c-checkbox__input:checked + .c-checkbox__label::before {
  background-image: url("data:image/svg+xml;charset=uft8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='currentColor' stroke-width='4' d='M1 13l8 8L23 5'/%3E%3C/svg%3E");
}

.c-toggle {
  position: relative;
  width: 2.75rem;
  height: 2.75rem;
}
@media (min-width: 45em) {
  .c-toggle {
    width: 3.125rem;
    height: 3.125rem;
  }
}
.c-toggle:not(.c-toggle--button) {
  color: rgba(0, 0, 0, 0.3);
}

.c-toggle__input {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  pointer-events: none;
}

.c-toggle__label {
  display: block;
  user-select: none;
  cursor: pointer;
  padding: 0.6875rem;
  box-shadow: 0 0 0 transparent;
  transition: color 150ms cubic-bezier(0.25, 0, 0.25, 1), background 150ms cubic-bezier(0.25, 0, 0.25, 1), box-shadow 150ms cubic-bezier(0.25, 0, 0.25, 1);
}
@media (min-width: 45em) {
  .c-toggle__label {
    padding: 0.875rem;
  }
}
.c-toggle__label:hover {
  background-color: rgba(0, 0, 0, 0.03);
}
.c-toggle__label:hover, .c-toggle__input:focus + .c-toggle__label, .c-toggle__input:checked + .c-toggle__label {
  color: black;
}
.c-toggle__input:focus-visible + .c-toggle__label, .c-toggle--button:focus-visible > .c-toggle__label {
  background-color: white;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1), 3px 2px 12px rgba(0, 0, 0, 0.15);
}
.c-toggle__label svg {
  width: 100%;
  height: 100%;
  fill: currentColor;
}

.c-button {
  display: inline-block;
  position: relative;
  font-weight: 900;
  text-align: center;
  color: white;
  background-color: black;
  padding: 0.6875em 1.875em 0.6875em;
  transition: transform 300ms cubic-bezier(0.23, 1, 0.32, 1), background-color 150ms cubic-bezier(0.25, 0, 0.25, 1), color 150ms cubic-bezier(0.25, 0, 0.25, 1);
}
.c-button:not([disabled]):hover, .c-button:focus {
  background-color: #143c9c;
}
.c-button--gray {
  color: black;
  background-color: #e6e6e6;
}
.c-button--gray:not([disabled]):hover, .c-button--gray:focus {
  color: white;
}
.c-button--small {
  padding: 0.5625em 1.5em 0.5em;
}
.c-button:not([disabled]):not(.c-button--loading):active {
  transform: scale(0.95);
}
.c-button--loading {
  cursor: wait;
}
.c-button[disabled]:not(.c-button--loading) {
  cursor: not-allowed;
  opacity: 0.33;
}

.c-button span {
  display: block;
  transition: transform 300ms cubic-bezier(0.25, 0, 0.25, 1);
}

.c-button--loading span {
  transform: translateX(0.75em);
}

@keyframes button-spinner-anim {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.c-button svg {
  fill: none;
  stroke: currentColor;
  stroke-width: 1.8;
  stroke-linecap: round;
  width: 1.125em;
  height: 1.125em;
  position: absolute;
  top: 50%;
  left: 1.5em;
  margin-top: -0.5625em;
  margin-left: -0.5625em;
  opacity: 0;
  transition: opacity 150ms cubic-bezier(0.25, 0, 0.25, 1);
}

.c-button--loading svg {
  opacity: 1;
  animation: button-spinner-anim 1s linear infinite;
}

.c-toggle-group__list {
  display: flex;
}

.c-legacy-warning {
  position: fixed;
  z-index: 100;
  top: 6.5rem;
  width: 100%;
  padding: 2rem;
  text-align: center;
  background-color: white;
  border: 4px solid black;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1), 3px 2px 12px rgba(0, 0, 0, 0.15);
}

.l-canvas {
  height: 100vh;
  overflow: hidden;
  display: grid;
}
.l-canvas > * {
  grid-area: 1/1/2/2;
}
.l-canvas canvas {
  position: relative;
  z-index: 2;
}
.l-canvas > .c-image {
  display: none;
  position: relative;
  z-index: 1;
}
.l-canvas > .c-image img {
  height: 100vh;
}
.l-canvas--fallback > .c-image {
  display: block;
}

.l-canvas__hyperreal {
  font-size: 16vw;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  transform: translate(var(--text-x, 0px), var(--text-y, 0px));
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  justify-content: center;
  align-items: center;
}
.l-canvas__hyperreal > span > span {
  opacity: 0;
}
.no-js .l-canvas__hyperreal > span > span {
  opacity: 1;
}

.c-pagination {
  display: flex;
  justify-content: space-between;
  margin: 6.5rem 1.5rem 0;
}

.c-pagination__prev,
.c-pagination__next {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, min-content));
  grid-gap: 1rem;
  align-items: center;
}
@media (min-width: 45em) {
  .c-pagination__prev,
.c-pagination__next {
    grid-gap: 2rem;
  }
}
.c-pagination__prev svg,
.c-pagination__next svg {
  width: 2.75rem;
  height: 1.6875rem;
  fill: none;
  stroke: currentColor;
  stroke-width: 1.5;
  padding: 0.25rem;
  box-sizing: content-box;
  transition: stroke 150ms cubic-bezier(0.25, 0, 0.25, 1);
}
.c-pagination__prev:hover svg, .c-pagination__prev:focus svg,
.c-pagination__next:hover svg,
.c-pagination__next:focus svg {
  stroke: #143c9c;
}
@media (hover: hover) and (pointer: fine) {
  .c-pagination__prev:hover .c-pagination__preview-image, .c-pagination__prev:focus .c-pagination__preview-image,
.c-pagination__next:hover .c-pagination__preview-image,
.c-pagination__next:focus .c-pagination__preview-image {
    opacity: 1;
  }
}

.c-pagination__prev svg {
  transform: rotate(180deg);
}

.c-pagination__next {
  margin-left: auto;
}

@media (hover: hover) and (pointer: fine) {
  .c-pagination__preview-image {
    opacity: 0;
    transition: opacity 150ms cubic-bezier(0.25, 0, 0.25, 1);
  }
}
.c-pagination__preview-image .c-image {
  height: 3rem;
}
.c-pagination__preview-image .c-image * {
  height: 100%;
  width: auto;
}
@media (min-width: 45em) {
  .c-pagination__preview-image .c-image {
    height: 4rem;
  }
  .c-pagination__preview-image .c-image * {
    height: 100%;
    width: auto;
  }
}

.c-close-button {
  width: 1.875rem;
  height: 1.875rem;
  background-color: white;
  border-radius: 1.5625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 5px 10px 1px rgba(0, 0, 0, 0.1);
  transition: background-color 150ms;
}
@media (min-width: 30em) {
  .c-close-button {
    width: 2.875rem;
    height: 2.875rem;
  }
}
.c-close-button svg {
  fill: none;
  stroke: black;
  stroke-width: 1.5;
  width: 0.625rem;
  height: 0.625rem;
  transition: stroke 150ms;
}
@media (min-width: 30em) {
  .c-close-button svg {
    width: 0.75rem;
    height: 0.75rem;
  }
}
.c-close-button:hover {
  background-color: #143c9c;
}
.c-close-button:hover svg {
  stroke: white;
}

.c-artist-teaser {
  display: block;
  position: relative;
}
.c-artist-teaser .c-image__wrapper {
  transform: scale(calc(1 + 0.2 * var(--scale-factor, 0))) translate(var(--x, 0), var(--y, 0));
  transition: transform 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}
.c-artist-teaser .c-caption {
  font-size: 1.25rem;
  color: white;
  pointer-events: none;
  padding: 0;
  width: fit-content;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(var(--caption-x, 0), var(--caption-y, 0));
  transition: opacity 0.1s, visibility 0.1s, transform 0s ease-out 0.1s;
  visibility: hidden;
  opacity: 0;
}
@media (min-width: 30em) {
  .c-artist-teaser .c-caption {
    font-size: calc(0.5vw + 1.1rem);
  }
}
@media (min-width: 80em) {
  .c-artist-teaser .c-caption {
    font-size: 1.5rem;
  }
}
.c-artist-teaser .c-caption span {
  display: block;
  transform: translate(-50%, -50%);
}
.c-artist-teaser--caption-visible .c-caption {
  transition: opacity 0.4s, visibility 0.4s, transform 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  visibility: visible;
  opacity: 1;
}

.c-order-button > * {
  position: fixed;
  z-index: 14;
  left: 1rem;
  bottom: 1.5rem;
  opacity: 0;
  visibility: hidden;
  transform: translateY(100%);
  transition: opacity 150ms cubic-bezier(0.25, 0, 0.25, 1) 0ms, transform 500ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, visibility 0ms linear 500ms;
}
@media (max-width: 29.9375em) {
  .c-order-button > * {
    display: none;
  }
}
.c-order-button--sticky > * {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition-delay: 0ms, 0ms, 0ms;
}

.c-raffle {
  position: relative;
  display: grid;
}
.c-raffle > * {
  grid-area: 1/1/2/2;
}
.c-raffle input[type=radio] {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
}

.c-raffle__selection {
  z-index: 2;
}

.c-raffle__overlay {
  fill: white;
  opacity: 0;
  transition: opacity 0.2s;
}

.c-raffle__check {
  fill: none;
  stroke: black;
  stroke-width: 4;
  opacity: 0;
  transition: opacity 0.2s;
}

.c-raffle__label {
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  cursor: pointer;
}
.c-raffle__label--blue-shower {
  clip-path: url(#blue-shower-clip);
}
.c-raffle__label--blue-shower:hover ~ svg #blue-shower-overlay .c-raffle__overlay, .c-raffle__label--blue-shower:focus ~ svg #blue-shower-overlay .c-raffle__overlay {
  opacity: 0.6;
}
.c-raffle__label--blue-shower:hover ~ svg #blue-shower-overlay .c-raffle__check, .c-raffle__label--blue-shower:focus ~ svg #blue-shower-overlay .c-raffle__check {
  opacity: 0.3;
}
.c-raffle__label--osaka {
  clip-path: url(#osaka-clip);
}
.c-raffle__label--osaka:hover ~ svg #osaka-overlay .c-raffle__overlay, .c-raffle__label--osaka:focus ~ svg #osaka-overlay .c-raffle__overlay {
  opacity: 0.6;
}
.c-raffle__label--osaka:hover ~ svg #osaka-overlay .c-raffle__check, .c-raffle__label--osaka:focus ~ svg #osaka-overlay .c-raffle__check {
  opacity: 0.3;
}
.c-raffle__label--debra {
  clip-path: url(#debra-clip);
}
.c-raffle__label--debra:hover ~ svg #debra-overlay .c-raffle__overlay, .c-raffle__label--debra:focus ~ svg #debra-overlay .c-raffle__overlay {
  opacity: 0.6;
}
.c-raffle__label--debra:hover ~ svg #debra-overlay .c-raffle__check, .c-raffle__label--debra:focus ~ svg #debra-overlay .c-raffle__check {
  opacity: 0.3;
}
.c-raffle__label--experiments-organics {
  clip-path: url(#experiments-organics-clip);
}
.c-raffle__label--experiments-organics:hover ~ svg #experiments-organics-overlay .c-raffle__overlay, .c-raffle__label--experiments-organics:focus ~ svg #experiments-organics-overlay .c-raffle__overlay {
  opacity: 0.6;
}
.c-raffle__label--experiments-organics:hover ~ svg #experiments-organics-overlay .c-raffle__check, .c-raffle__label--experiments-organics:focus ~ svg #experiments-organics-overlay .c-raffle__check {
  opacity: 0.3;
}
.c-raffle__label--table-of-contents {
  clip-path: url(#table-of-contents-clip);
}
.c-raffle__label--table-of-contents:hover ~ svg #table-of-contents-overlay .c-raffle__overlay, .c-raffle__label--table-of-contents:focus ~ svg #table-of-contents-overlay .c-raffle__overlay {
  opacity: 0.6;
}
.c-raffle__label--table-of-contents:hover ~ svg #table-of-contents-overlay .c-raffle__check, .c-raffle__label--table-of-contents:focus ~ svg #table-of-contents-overlay .c-raffle__check {
  opacity: 0.3;
}
.c-raffle__label--danedream {
  clip-path: url(#danedream-clip);
}
.c-raffle__label--danedream:hover ~ svg #danedream-overlay .c-raffle__overlay, .c-raffle__label--danedream:focus ~ svg #danedream-overlay .c-raffle__overlay {
  opacity: 0.6;
}
.c-raffle__label--danedream:hover ~ svg #danedream-overlay .c-raffle__check, .c-raffle__label--danedream:focus ~ svg #danedream-overlay .c-raffle__check {
  opacity: 0.3;
}

.c-raffle input#blue-shower[type=radio]:checked ~ svg #blue-shower-overlay .c-raffle__overlay {
  opacity: 0.5;
}
.c-raffle input#blue-shower[type=radio]:checked ~ svg #blue-shower-overlay .c-raffle__check {
  opacity: 1;
}
.c-raffle input#osaka[type=radio]:checked ~ svg #osaka-overlay .c-raffle__overlay {
  opacity: 0.5;
}
.c-raffle input#osaka[type=radio]:checked ~ svg #osaka-overlay .c-raffle__check {
  opacity: 1;
}
.c-raffle input#debra[type=radio]:checked ~ svg #debra-overlay .c-raffle__overlay {
  opacity: 0.5;
}
.c-raffle input#debra[type=radio]:checked ~ svg #debra-overlay .c-raffle__check {
  opacity: 1;
}
.c-raffle input#experiments-organics[type=radio]:checked ~ svg #experiments-organics-overlay .c-raffle__overlay {
  opacity: 0.5;
}
.c-raffle input#experiments-organics[type=radio]:checked ~ svg #experiments-organics-overlay .c-raffle__check {
  opacity: 1;
}
.c-raffle input#table-of-contents[type=radio]:checked ~ svg #table-of-contents-overlay .c-raffle__overlay {
  opacity: 0.5;
}
.c-raffle input#table-of-contents[type=radio]:checked ~ svg #table-of-contents-overlay .c-raffle__check {
  opacity: 1;
}
.c-raffle input#danedream[type=radio]:checked ~ svg #danedream-overlay .c-raffle__overlay {
  opacity: 0.5;
}
.c-raffle input#danedream[type=radio]:checked ~ svg #danedream-overlay .c-raffle__check {
  opacity: 1;
}

.l-header {
  padding-top: 1.5rem;
  padding-left: var(--padding-x-base);
  padding-right: var(--padding-x-base);
  width: 100%;
  height: 4rem;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, auto) minmax(0, 1fr);
  grid-gap: 2rem;
  align-items: start;
}
@media (min-width: 45em) {
  .l-header {
    padding-top: 2.25rem;
    height: 5rem;
  }
}

.l-header__icon {
  position: relative;
  z-index: 10;
  justify-self: start;
}
.l-header__icon svg {
  fill: currentColor;
  width: 1.75rem;
  height: 1.125rem;
}
@media (min-width: 45em) {
  .l-header__icon svg {
    width: 2.5rem;
    height: 1.625rem;
  }
}

.l-header__logo {
  position: relative;
  z-index: 10;
}
.l-header__logo svg {
  fill: currentColor;
  width: 12.5rem;
  height: 1.125rem;
}
@media (min-width: 45em) {
  .l-header__logo svg {
    width: 17.8125rem;
    height: 1.625rem;
  }
}

.l-header__close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 2;
}
@media (min-width: 30em) {
  .l-header__close-button {
    top: 2rem;
    right: 2.25rem;
  }
}

.l-footer {
  background-color: #f7f7f7;
  font-weight: bold;
  font-size: 1rem;
  padding-top: 4rem;
  padding-bottom: 3.25rem;
  padding-left: var(--padding-x-base);
  padding-right: var(--padding-x-base);
}
@media (min-width: 45em) {
  .l-footer {
    padding-top: 5rem;
  }
}
@media (max-width: 59.9375em) {
  .l-footer > * + * {
    margin-top: 5rem;
  }
}
@media (min-width: 60em) {
  .l-footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.l-footer__logo {
  display: flex;
  align-items: center;
}
.l-footer__logo svg {
  fill: currentColor;
  width: 15.8125rem;
  height: 1.125rem;
}
@media (min-width: 45em) {
  .l-footer__logo svg {
    width: 22.625rem;
    height: 1.625rem;
  }
}

@media (min-width: 45em) {
  .l-footer__nav {
    display: flex;
  }
}
.l-footer__nav ul:nth-child(1) {
  display: grid;
  grid-template-columns: minmax(0, auto);
  grid-row-gap: 0.625rem;
}
@media (min-width: 30em) {
  .l-footer__nav ul:nth-child(1) {
    grid-template-columns: repeat(2, minmax(0, auto));
    grid-template-rows: repeat(var(--row-count), minmax(0, auto));
    grid-auto-flow: column;
    grid-column-gap: 4.5rem;
    justify-content: start;
  }
}
@media (min-width: 60em) and (max-width: 65em) {
  .l-footer__nav ul:nth-child(1) {
    grid-column-gap: 2.75rem;
  }
}
@media (max-width: 44.9375em) {
  .l-footer__nav ul:nth-child(2) {
    margin-top: 3.25rem;
  }
}
@media (min-width: 45em) {
  .l-footer__nav ul:nth-child(2) {
    margin-left: 4.5rem;
  }
}
@media (min-width: 60em) and (max-width: 65em) {
  .l-footer__nav ul:nth-child(2) {
    margin-left: 3rem;
  }
}
.l-footer__nav a {
  position: relative;
}
.l-footer__nav a::after {
  content: "";
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  position: absolute;
  padding: 0.3125rem 0;
  top: -0.3125rem;
  left: 0;
}

.l-footer__copyright {
  font-weight: normal;
  margin-top: 4rem;
}

.l-article > div > *:not(.l-article__wide) {
  max-width: 40rem;
}
.l-article > div > .c-blockquote {
  margin-left: 1.5rem;
}
@media (min-width: 45em) {
  .l-article > div > .c-blockquote {
    margin-left: 2rem;
  }
}
.l-article--center > div {
  text-align: center;
}
.l-article--center > div > *:not(.l-article__wide) {
  margin-left: auto;
  margin-right: auto;
}

.l-teaser {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4rem 3rem;
}
@media (min-width: 45em) {
  .l-teaser {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-row-gap: 6rem;
  }
}

.l-teaser__item {
  position: relative;
}
.l-teaser__item > *:not(.c-image) {
  max-width: 21rem;
}

.l-teaser__link::after {
  content: "";
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  position: absolute;
  padding: 0 0;
  top: 0;
  left: 0;
}

.l-teaser__more {
  font-weight: bold;
  text-decoration: underline;
}
.l-teaser__item:hover .l-teaser__more, .l-teaser__item:focus-within .l-teaser__more {
  text-decoration: none;
  color: #143c9c;
}

.l-offices {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4rem;
}
@media (min-width: 30em) {
  .l-offices {
    grid-template-columns: repeat(auto-fit, minmax(20.3125rem, 1fr));
  }
}
@media (min-width: 45em) {
  .l-offices {
    grid-row-gap: 6rem;
  }
}

@media (min-width: 45em) {
  .l-headline {
    text-align: center;
  }
}
.l-headline--sticky {
  min-height: 6rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
@media (min-width: 45em) {
  .l-headline--sticky {
    min-height: 9rem;
  }
}
.l-headline--sticky > * {
  position: sticky;
  bottom: 1.5rem;
  width: 100%;
}

.l-text-images-scroll-layout {
  display: grid;
  grid-gap: 4rem;
}
@media (min-width: 45em) {
  .l-text-images-scroll-layout {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 6rem;
    align-items: stretch;
  }
}

@media (min-width: 45em) {
  .l-text-images-scroll-layout__text {
    position: sticky;
    top: 0;
    min-height: 100vh;
    display: flex;
    align-items: center;
  }
}

@media (min-width: 45em) {
  .l-text-images-scroll-layout__images {
    padding: calc((100vh - var(--height, 80vh)) / 2) 0;
  }
}
.l-text-images-scroll-layout__images > * {
  position: sticky;
  top: 4rem;
}
@media (min-width: 45em) {
  .l-text-images-scroll-layout__images > * {
    width: 65vmin;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    top: calc((100vh - var(--height, 80vh)) / 2);
  }
}

.l-text-image-layout {
  display: grid;
  grid-gap: 4rem;
}
@media (min-width: 45em) {
  .l-text-image-layout {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 8rem;
    align-items: center;
  }
}

.l-info-box__hl {
  font-size: 1.5rem;
  line-height: 1.35;
  font-weight: 900;
}
@media (min-width: 30em) {
  .l-info-box__hl {
    font-size: calc(0.75vw + 1.275rem);
  }
}
@media (min-width: 80em) {
  .l-info-box__hl {
    font-size: 1.875rem;
  }
}

.l-info-box__text {
  margin-top: 2rem;
}
.l-info-box__text p {
  font-size: 1.25rem;
}
@media (min-width: 30em) {
  .l-info-box__text p {
    font-size: calc(0.5vw + 1.1rem);
  }
}
@media (min-width: 80em) {
  .l-info-box__text p {
    font-size: 1.5rem;
  }
}
.l-info-box__text p + p {
  margin-top: 1.75rem;
}
.l-info-box__text a {
  transition: color 150ms cubic-bezier(0.25, 0, 0.25, 1);
  text-decoration: underline;
  text-decoration-thickness: 0.0625em;
  text-underline-offset: 2px;
}
.l-info-box__text a:hover, .l-info-box__text a:focus {
  text-decoration: none;
}
@media (min-width: 45em) {
  .l-info-box__text {
    margin-top: 3rem;
  }
  .l-info-box__text p + p {
    margin-top: 2.5rem;
  }
}

.l-slideshow__slideshow {
  position: relative;
}

.l-slideshow__wrapper {
  overflow-y: hidden;
  overflow-x: scroll;
}
html:not(.no-js) .l-slideshow__wrapper {
  scrollbar-width: none;
}
html:not(.no-js) .l-slideshow__wrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.l-slideshow__slides {
  display: grid;
  width: calc(var(--slide-count) * 100%);
  grid-auto-columns: calc(100% / var(--slide-count));
  grid-auto-flow: column;
  cursor: url("../img/cursor-white-1x.png") 24 24, grab !important;
  cursor: image-set(url("../img/cursor-white-1x.png") 1x, url("../img/cursor-white-2x.png") 2x) 24 24, grab !important;
}

.l-free-image .c-image:nth-child(1) {
  width: 80%;
}
.l-free-image .c-image:nth-child(2) {
  width: 60%;
  margin-left: auto;
  margin-top: -2rem;
}
.l-free-image .c-image:nth-child(3) {
  width: 60%;
  margin-top: 3rem;
}
@media (min-width: 45em) {
  .l-free-image .c-image:nth-child(1) {
    width: 64%;
  }
  .l-free-image .c-image:nth-child(2) {
    width: 44%;
    margin-top: -3rem;
  }
  .l-free-image .c-image:nth-child(3) {
    width: 44%;
    margin-top: 5rem;
  }
}
@media (min-width: 60em) {
  .l-free-image .c-image:nth-child(1) {
    margin-left: 2rem;
  }
  .l-free-image .c-image:nth-child(2) {
    margin-right: 2rem;
  }
  .l-free-image .c-image:nth-child(3) {
    margin-left: 2rem;
  }
}

.l-free-image-text {
  overflow: hidden;
}

.l-free-image-text__hl {
  text-align: center;
  font-weight: 700;
}

.l-free-image-text__hyperreal {
  font-size: 16vw;
  text-align: center;
  font-weight: 700;
  line-height: 1;
  z-index: 15;
  position: relative;
  pointer-events: none;
}

.l-free-image-text__flex {
  display: flex;
  align-items: flex-start;
}

.l-free-image-text__artist--artist1 {
  width: 55%;
  margin-top: 20%;
  margin-left: -4%;
}
.l-free-image-text__artist--artist2 {
  width: 38%;
  margin-top: -3.5%;
  margin-right: 4%;
  margin-left: auto;
}
.l-free-image-text__artist--artist3 {
  width: 35%;
  margin-top: 12%;
  margin-left: 7%;
}
.l-free-image-text__artist--artist4 {
  width: 55%;
  margin-top: 40%;
  margin-right: -3%;
  margin-left: auto;
}
.l-free-image-text__artist--artist5 {
  width: 55%;
  margin-top: -8%;
  margin-left: 7%;
}
.l-free-image-text__artist--artist6 {
  width: 40%;
  margin-top: 43%;
}
.l-free-image-text__artist--artist7 {
  width: 45%;
  margin-top: 13%;
  margin-left: auto;
  margin-right: 7%;
}
.l-free-image-text__artist--artist8 {
  width: 40%;
  margin-top: -9.5%;
  margin-left: 20%;
}
.l-free-image-text__artist--artist9 {
  width: 45.5%;
  margin-top: 10%;
}
.l-free-image-text__artist--artist10 {
  width: 66%;
  margin-right: 0;
  margin-left: auto;
  margin-top: 14%;
}
.l-free-image-text__artist--artist11 {
  width: 40%;
  margin-top: -8%;
  margin-left: 19%;
}
.l-free-image-text__artist--artist12 {
  width: 64%;
  margin-top: 93%;
  margin-right: -18%;
}
.l-free-image-text__artist--artist13 {
  width: 39%;
  margin-top: 18%;
  margin-left: auto;
  margin-right: 10%;
}
.l-free-image-text__artist--artist14 {
  width: 56%;
  margin-top: -5.5%;
  margin-left: 22.5%;
  margin-bottom: 5%;
}
@media (min-width: 45em) {
  .l-free-image-text__artist--artist1 {
    width: 41.25%;
    margin-top: 11%;
    margin-left: -2.25%;
  }
  .l-free-image-text__artist--artist2 {
    width: 26.25%;
    margin-top: -3.5%;
    margin-right: 7%;
    margin-left: auto;
  }
  .l-free-image-text__artist--artist3 {
    width: 26.25%;
    margin-left: 7%;
    margin-top: -3%;
  }
  .l-free-image-text__artist--artist4 {
    width: 41.25%;
    margin-top: 10%;
    margin-right: -3%;
    margin-left: auto;
  }
  .l-free-image-text__artist--artist5 {
    width: 41.25%;
    margin-top: -12%;
    margin-left: 33.25%;
  }
  .l-free-image-text__artist--artist6 {
    width: 26.25%;
    margin-top: 37%;
    margin-left: -8%;
  }
  .l-free-image-text__artist--artist7 {
    width: 41.25%;
    margin-top: 7%;
    margin-left: auto;
    margin-right: 7%;
  }
  .l-free-image-text__artist--artist8 {
    width: 26.25%;
    margin-top: -9.5%;
    margin-left: 14.5%;
  }
  .l-free-image-text__artist--artist9 {
    width: 26.25%;
    margin-top: 10%;
    margin-left: 7%;
  }
  .l-free-image-text__artist--artist10 {
    width: 41.25%;
    margin-right: -4%;
    margin-left: auto;
    margin-top: -35%;
  }
  .l-free-image-text__artist--artist11 {
    width: 26.25%;
    margin-top: -6%;
    margin-left: 48%;
  }
  .l-free-image-text__artist--artist12 {
    width: 47%;
    margin-top: 25%;
    margin-left: -3%;
  }
  .l-free-image-text__artist--artist13 {
    width: 26.25%;
    margin-top: 14.25%;
    margin-left: auto;
    margin-right: 5%;
    z-index: 13;
  }
  .l-free-image-text__artist--artist14 {
    width: 40%;
    margin-top: -5.5%;
    margin-left: 22.5%;
    margin-bottom: 10%;
    z-index: 12;
  }
}

.l-free-image-text__text1,
.l-free-image-text__text2,
.l-free-image-text__text3 {
  font-size: 1.25rem;
  margin-left: var(--padding-x-base);
  margin-right: var(--padding-x-base);
}
@media (min-width: 30em) {
  .l-free-image-text__text1,
.l-free-image-text__text2,
.l-free-image-text__text3 {
    font-size: calc(0.5vw + 1.1rem);
  }
}
@media (min-width: 80em) {
  .l-free-image-text__text1,
.l-free-image-text__text2,
.l-free-image-text__text3 {
    font-size: 1.5rem;
  }
}
@media (min-width: 60em) {
  .l-free-image-text__text1,
.l-free-image-text__text2,
.l-free-image-text__text3 {
    width: 46%;
  }
}

.l-free-image-text__text1 {
  margin-top: 13%;
}
@media (min-width: 60em) {
  .l-free-image-text__text1 {
    margin-top: 15.5%;
    margin-left: auto;
    margin-right: 7%;
  }
}

.l-free-image-text__text2 {
  margin-top: 13%;
}
@media (min-width: 60em) {
  .l-free-image-text__text2 {
    margin-top: 14.25%;
    margin-left: 10.25%;
  }
}

.l-free-image-text__text3 {
  margin-top: 13%;
}
@media (min-width: 60em) {
  .l-free-image-text__text3 {
    margin-top: 10%;
    margin-left: 40.5%;
  }
}

.l-artist {
  --margin-left: var(--padding-x-base);
}
@media (min-width: 30em) {
  .l-artist {
    --margin-left: 5rem;
  }
}
@media (min-width: 60em) {
  .l-artist {
    --margin-left: 13rem;
  }
}

.l-artist__hero {
  position: relative;
  overflow: hidden;
}

.l-artist__hl {
  position: absolute;
  top: 3.5rem;
  left: 0;
  width: 100%;
  font-size: 1.5rem;
  line-height: 1.35;
}
@media (min-width: 30em) {
  .l-artist__hl {
    font-size: calc(0.75vw + 1.275rem);
  }
}
@media (min-width: 80em) {
  .l-artist__hl {
    font-size: 1.875rem;
  }
}
@media (min-width: 30em) {
  .l-artist__hl {
    top: 6rem;
  }
}
@media (min-width: 60em) {
  .l-artist__hl {
    top: 7rem;
  }
}
.l-artist__hl span {
  display: block;
}
.l-artist__hl span:first-child {
  font-weight: 900;
}

.l-artist__frame-left {
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  width: var(--margin-left);
  height: 100%;
  z-index: 2;
  transform: translateX(-100%);
  visibility: hidden;
}
@media (min-width: 30em) {
  .l-artist__frame-left {
    visibility: visible;
  }
}

.l-artist__content {
  font-size: 1.25rem;
  margin-top: 4rem;
  margin-left: var(--margin-left);
  max-width: 51rem;
  padding-right: var(--padding-x-base);
}
@media (min-width: 30em) {
  .l-artist__content {
    font-size: calc(0.5vw + 1.1rem);
  }
}
@media (min-width: 80em) {
  .l-artist__content {
    font-size: 1.5rem;
  }
}
@media (min-width: 30em) {
  .l-artist__content {
    margin-top: 5rem;
  }
}
@media (min-width: 60em) {
  .l-artist__content {
    margin-top: 6rem;
  }
}

.l-artist__about-hl {
  margin-top: 5rem;
  font-weight: 900;
}

.l-artist__about-text {
  margin-top: 2.5rem;
}

.l-artist__link {
  margin-top: 4.5rem;
  display: block;
  width: fit-content;
}
@media (min-width: 45em) {
  .l-artist__link {
    display: inline-block;
  }
}
.l-artist__link + .l-artist__link {
  margin-top: 1rem;
}
@media (min-width: 45em) {
  .l-artist__link + .l-artist__link {
    margin-left: 3rem;
  }
}

@media (min-width: 45em) and (min-aspect-ratio: 4/3) {
  .l-hero img {
    height: calc(100vh - 6rem);
    min-height: 28.125rem;
  }
}
@media (min-width: 45em) and (min-aspect-ratio: 4/3) {
  .l-hero--video video {
    height: calc(100vh - 6rem);
    min-height: 28.125rem;
    object-fit: cover;
  }
  .l-hero--video svg {
    display: none;
  }
}

.l-order-form__hnpt {
  position: absolute;
  left: -9999px;
}

.l-order-form--success .l-order-form__text {
  display: none;
}

.l-order-form--success .l-order-form__form {
  display: none;
}

.l-order-form__error {
  color: #b32929;
  display: none;
}
.l-order-form__error--visible {
  display: block;
}

.l-order-form__success {
  display: none;
}
.l-order-form--success .l-order-form__success {
  display: block;
}

.l-dummy-hero.hidden,
.l-dummy__form-step-1.hidden,
.l-dummy__form-step-2.hidden {
  display: none;
}

.l-dummy__help {
  font-size: 1rem;
  color: #888;
  margin-top: 1.125rem;
}

.l-dummy__quantity {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.l-dummy__quantity label {
  width: 6rem;
}
.l-dummy__quantity select {
  width: 6rem;
}

.l-dummy__format {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  gap: 0.5rem 1rem;
}
@media (max-width: 29.9375em) {
  .l-dummy__format {
    flex-wrap: wrap;
  }
}
.l-dummy__format .c-input {
  flex-grow: 1;
}

.l-dummy__format-label {
  width: 6rem;
}
@media (max-width: 29.9375em) {
  .l-dummy__format-label {
    width: 100%;
  }
}

.l-dummy__binding {
  margin-top: 3rem;
}
.l-dummy__binding select {
  margin-top: 0.5rem;
}

.l-dummy__folded-binding {
  margin-top: 3rem;
}
.l-dummy__folded-binding.hidden {
  display: none;
}

.l-dummy__folded-binding-radio {
  margin-top: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}
.l-dummy__folded-binding-radio input:checked + label path {
  fill: black;
  stroke: white;
}
.l-dummy__folded-binding-radio label {
  cursor: pointer;
}
.l-dummy__folded-binding-radio > div label,
.l-dummy__folded-binding-radio > div svg {
  display: block;
  width: 100%;
}
.l-dummy__folded-binding-radio > div:nth-child(1) {
  width: 18%;
}
.l-dummy__folded-binding-radio > div:nth-child(2) {
  width: 7%;
}
.l-dummy__folded-binding-radio > div:nth-child(3) {
  width: 11%;
}
.l-dummy__folded-binding-radio > div:nth-child(4) {
  width: 7%;
}
.l-dummy__folded-binding-radio > div:nth-child(5) {
  width: 10%;
}
.l-dummy__folded-binding-radio > div:nth-child(6) {
  width: 12%;
}

.l-dummy__cover {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3rem;
}
.l-dummy__cover label {
  background-color: rgba(0, 0, 0, 0.03);
  color: rgba(0, 0, 0, 0.2);
  padding: 0.5rem 1rem;
  display: block;
  text-align: center;
  border: 1px solid transparent;
  min-width: 6rem;
  cursor: pointer;
}
.l-dummy__cover input:checked + label {
  background: white;
  color: black;
  border: 1px solid black;
}

.l-dummy__cover-label {
  width: 100%;
  margin-bottom: 0.5rem;
}

.l-dummy__cover-paper {
  margin-top: 3rem;
}
.l-dummy__cover-paper.hidden {
  display: none;
}
.l-dummy__cover-paper label {
  display: block;
  margin-bottom: 0.5rem;
}

.l-dummy__paper-input {
  display: grid;
  grid-template-columns: 5fr 3fr 2fr;
  gap: 1rem;
}
@media (max-width: 29.9375em) {
  .l-dummy__paper-input > div:first-child {
    grid-column: 1/-1;
  }
  .l-dummy__paper-input > div:last-child {
    grid-column: 2/-1;
  }
}

.l-dummy__insert-paper {
  margin-top: 3.5rem;
}
.l-dummy__insert-paper label {
  display: block;
  margin-bottom: 0.5rem;
}
.l-dummy__insert-paper .l-dummy__paper-input {
  position: relative;
}
.l-dummy__insert-paper .l-dummy__paper-input.hidden {
  display: none;
}
.l-dummy__insert-paper .l-dummy__paper-input .l-dummy__insert-paper-remove {
  margin-top: 2rem;
}
@media (min-width: 30em) {
  .l-dummy__insert-paper .l-dummy__paper-input .l-dummy__insert-paper-remove {
    position: absolute;
    left: calc(100% + 1rem);
    top: 1rem;
  }
}
.l-dummy__insert-paper .l-dummy__paper-input + .l-dummy__paper-input {
  margin-top: 2.5rem;
}
@media (min-width: 30em) {
  .l-dummy__insert-paper .l-dummy__paper-input + .l-dummy__paper-input {
    margin-top: 1.5rem;
  }
}
@media (min-width: 30em) {
  .l-dummy__insert-paper .l-dummy__paper-input + .l-dummy__paper-input label {
    display: none;
  }
}

.l-dummy-insert-paper-more {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.l-dummy-insert-paper-more:disabled {
  opacity: 0.33;
  cursor: not-allowed;
}

.l-dummy__insert-paper-max-count {
  color: red;
}
.l-dummy__insert-paper-max-count.hidden {
  display: none;
}

.l-dummy-checkout-list > div + div {
  margin-top: 0.5rem;
}
.l-dummy-checkout-list > div {
  display: grid;
  grid-template-columns: minmax(0, 7rem) 1fr;
  column-gap: 1rem;
}
.l-dummy-checkout-list > div.hidden {
  display: none;
}

.l-dummy-discount-form__wrapper {
  display: flex;
  gap: 1rem;
  align-items: flex-end;
}
.l-dummy-discount-form__wrapper .c-input {
  flex-grow: 1;
}
.l-dummy-discount-form__wrapper .c-button {
  padding: 0.625em 1.875em 0.625em;
}

.l-dummy-discount-form__success {
  display: none;
}
.l-dummy-discount-form__success.visible {
  display: block;
}

.l-dummy-discount-form__error {
  display: none;
}
.l-dummy-discount-form__error.visible {
  display: block;
}

.l-dummy-order-form__hnpt {
  position: absolute;
  left: -9999px;
}

.l-dummy-order-form--success .l-dummy-order-form__text {
  display: none;
}

.l-dummy-order-form--success .l-dummy-order-form__form {
  display: none;
}

.l-dummy-order-form__error {
  color: #b32929;
  display: none;
}
.l-dummy-order-form__error--visible {
  display: block;
}

.l-dummy-order-form__success {
  display: none;
}
.l-dummy-order-form--success .l-dummy-order-form__success {
  display: block;
}