.c-list {
  padding-left: 1.25em;

  > li {
    position: relative;

    &::before {
      position: absolute;
    }

    & + li {
      margin-top: 0.25em;
    }
  }

  &--unordered {
    > li::before {
      content: '';
      width: 5px;
      height: 5px;
      background-color: #000;
      border-radius: 50%;
      left: -1em;
      top: em(11);
    }
  }

  &--ordered {
    list-style-type: none;
    counter-reset: ordered-list;

    > li::before {
      counter-increment: ordered-list;
      content: counter(ordered-list) '.';
      left: -1.25em;
    }
  }
}
