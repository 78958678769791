.c-blockquote {
  quotes: '“' '”';

  > p::before {
    content: open-quote;
  }

  > p::after {
    content: close-quote;
  }

  > p {
    @include font-size-fluid($font-size-quote);
  }

  footer {
    padding-top: 1.25em;
    @include font-size-fluid($font-size-small);
    font-weight: 900;
  }
}
