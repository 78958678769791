html {
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-wrap: break-word;
  overflow-wrap: break-word;
  box-sizing: border-box;
  // font-variant: no-common-ligatures;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: inherit;
  font-size: inherit;
}

strong {
  font-weight: $font-weight-bold;
}

ul,
ol,
dl,
dt,
dd,
p {
  margin: 0;
  padding: 0;
}

ul li,
ol li {
  padding: 0;
  list-style-type: none;
}

blockquote,
figure {
  margin: 0;
}

figcaption {
  font-weight: inherit;
}

img,
video,
picture {
  display: block;
  width: 100%;
  height: auto;
}

svg {
  display: block;
}

iframe {
  border: none;
}

address {
  font-style: normal;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  border: 0;
  border-radius: 0;
  color: inherit;
  background: none;
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  text-transform: inherit;
  cursor: pointer;
}

input {
  font-family: inherit;
  font-size: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  text-transform: none;
  appearance: none;
  display: block;
  width: 100%;
  margin: 0;
  border-radius: 0;
}

select {
  font-family: inherit;
  font-size: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  text-transform: none;
  appearance: none;
  display: block;
  width: 100%;
  margin: 0;
  border-radius: 0;
}

fieldset {
  padding: 0;
  margin: 0;
  border: none;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: block;
  width: 100%;
  padding: 0;
  white-space: normal;
}

// Hide outline without degrading a11y,
// High Contrast Mode should still work
a,
button {
  &:focus:not(:focus-visible) {
    outline-color: transparent;
    outline-style: solid; // overwrite outline:auto in Blink
  }
}

*,
*::before,
*::after {
  @media (prefers-reduced-motion: reduce) {
    animation-duration: 0.001s !important;
    duration-default: 0.001s !important;
    transition-delay: 0s !important;
  }
}
