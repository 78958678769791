.l-free-image {
  .c-image {
    &:nth-child(1) {
      width: 80%;
    }

    &:nth-child(2) {
      width: 60%;
      margin-left: auto;
      margin-top: -2rem;
    }

    &:nth-child(3) {
      width: 60%;
      margin-top: 3rem;
    }

    @media ($min-ms) {
      &:nth-child(1) {
        width: 64%;
      }

      &:nth-child(2) {
        width: 44%;
        margin-top: -3rem;
      }

      &:nth-child(3) {
        width: 44%;
        margin-top: 5rem;
      }
    }

    @media ($min-m) {
      &:nth-child(1) {
        margin-left: 2rem;
      }

      &:nth-child(2) {
        margin-right: 2rem;
      }

      &:nth-child(3) {
        margin-left: 2rem;
      }
    }
  }
}
