// Center
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

// Base margin towards header
.mt-base-large {
  margin-top: rem(150) - 4rem;

  @media ($min-ms) {
    margin-top: rem(240) - 5rem;
  }
}

.mt-base-small {
  margin-top: rem(110) - 4rem;

  @media ($min-ms) {
    margin-top: rem(120) - 5rem;
  }
}

.mt-base-special {
  @media ($min-ms) {
    margin-top: -5rem;
  }
}

.mt-base-canvas {
  margin-top: -4rem;

  @media ($min-ms) {
    margin-top: -5rem;
  }
}

@for $i from 1 through 10 {
  .mt-#{$i} {
    margin-top: var(--space-#{$i});
  }

  .mb-#{$i} {
    margin-bottom: var(--space-#{$i});
  }
}
