.l-order-form {
}

.l-order-form__hnpt {
  position: absolute;
  left: -9999px;
}

.l-order-form__text {
  .l-order-form--success & {
    display: none;
  }
}

.l-order-form__form {
  .l-order-form--success & {
    display: none;
  }
}

.l-order-form__error {
  color: rgb(179, 41, 41);
  display: none;

  &--visible {
    display: block;
  }
}

.l-order-form__success {
  display: none;

  .l-order-form--success & {
    display: block;
  }
}
