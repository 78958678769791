.l-artist {
  --margin-left: var(--padding-x-base);

  @media ($min-s) {
    --margin-left: 5rem;
  }

  @media ($min-m) {
    --margin-left: 13rem;
  }
}

.l-artist__hero {
  position: relative;
  overflow: hidden;
}

.l-artist__hl {
  position: absolute;
  top: 3.5rem;
  left: 0;
  width: 100%;
  @include font-size-fluid($font-size-large);
  line-height: $line-height-medium;

  @media ($min-s) {
    top: 6rem;
  }

  @media ($min-m) {
    top: 7rem;
  }

  span {
    display: block;
  }

  span:first-child {
    font-weight: 900;
  }
}

.l-artist__frame-left {
  background-color: $color-white;
  position: absolute;
  top: 0;
  left: 0;
  width: var(--margin-left);
  height: 100%;
  z-index: 2;
  transform: translateX(-100%);
  visibility: hidden;

  @media ($min-s) {
    visibility: visible;
  }
}

.l-artist__content {
  @include font-size-fluid($font-size-medium);
  margin-top: 4rem;
  margin-left: var(--margin-left);
  max-width: $max-width-medium;
  padding-right: var(--padding-x-base);

  @media ($min-s) {
    margin-top: 5rem;
  }

  @media ($min-m) {
    margin-top: 6rem;
  }
}

.l-artist__about-hl {
  margin-top: 5rem;
  font-weight: 900;
}

.l-artist__about-text {
  margin-top: 2.5rem;
}

.l-artist__link {
  margin-top: 4.5rem;
  display: block;
  width: fit-content;

  @media ($min-ms) {
    display: inline-block;
  }

  & + & {
    margin-top: 1rem;

    @media ($min-ms) {
      margin-left: 3rem;
    }
  }
}
