::selection {
  background-color: $color-black;
  color: $color-white;
}

html {
  font-family: $font-family-default;
  font-size: 1em;
  color: $color-black;
  background-color: $color-white;
}

body {
  @include font-size-fluid($font-size-default);
  line-height: $line-height-default;
  letter-spacing: $letter-spacing-default;
  background-color: $color-white;
  min-height: 100vh;
  overflow-y: scroll; // always show scrollbar
  position: relative;
  display: flex;
  flex-direction: column;

  // prevent long word wrap
  @media ($max-xs) {
    hyphens: auto;
  }
}

main {
  flex-grow: 1; // Footer always at bottom of viewport
}
