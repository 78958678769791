.c-close-button {
  width: rem(30);
  height: rem(30);
  background-color: $color-white;
  border-radius: rem(25);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 5px 10px 1px rgba(0, 0, 0, 0.1);
  transition: background-color 150ms;

  @media ($min-s) {
    width: rem(46);
    height: rem(46);
  }

  svg {
    fill: none;
    stroke: $color-black;
    stroke-width: 1.5;
    width: rem(12 * 0.8);
    height: rem(12 * 0.8);
    transition: stroke 150ms;

    @media ($min-s) {
      width: rem(12);
      height: rem(12);
    }
  }

  &:hover {
    background-color: $color-blue;

    svg {
      stroke: $color-white;
    }
  }
}
