.l-info-box {
}

.l-info-box__hl {
  @include font-size-fluid($font-size-hl-2);
  line-height: $line-height-medium;
  font-weight: 900;
}

.l-info-box__text {
  margin-top: 2rem;

  p {
    @include font-size-fluid((rem(20), rem(24)));
  }

  p + p {
    margin-top: 1.75rem;
  }

  a {
    transition: color 150ms $ease-fade;
    text-decoration: underline;
    text-decoration-thickness: em(1);
    text-underline-offset: 2px;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  @media ($min-ms) {
    margin-top: 3rem;

    p + p {
      margin-top: 2.5rem;
    }
  }
}
