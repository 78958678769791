.l-hero {
  @media ($min-ms) and (min-aspect-ratio: 4/3) {
    img {
      height: calc(100vh - 6rem);
      min-height: rem(450);
    }
  }

  &--video {
    @media ($min-ms) and (min-aspect-ratio: 4/3) {
      video {
        height: calc(100vh - 6rem);
        min-height: rem(450);
        object-fit: cover;
      }

      // Disable aspect ratio placeholder
      svg {
        display: none;
      }
    }
  }
}
