.c-text--l {
  @include font-size-fluid($font-size-large);
  line-height: $line-height-medium;
}

.c-text--m {
  @include font-size-fluid($font-size-medium);
}

.c-text--s {
  @include font-size-fluid($font-size-small);
}
