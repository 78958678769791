.l-header {
  padding-top: 1.5rem;
  padding-left: var(--padding-x-base);
  padding-right: var(--padding-x-base);
  width: 100%;
  height: 4rem;
  display: grid;
  grid-template-columns:
    minmax(0, 1fr) minmax(0, auto)
    minmax(0, 1fr);
  grid-gap: 2rem;
  align-items: start;

  @media ($min-ms) {
    padding-top: 2.25rem;
    height: 5rem;
  }
}

.l-header__icon {
  position: relative;
  z-index: 10;
  justify-self: start;

  svg {
    fill: currentColor;
    width: rem(40 * 0.7);
    height: rem(26 * 0.7);

    @media ($min-ms) {
      width: rem(40);
      height: rem(26);
    }
  }
}

.l-header__logo {
  position: relative;
  z-index: 10;

  svg {
    fill: currentColor;
    width: rem(285 * 0.7);
    height: rem(26 * 0.7);

    @media ($min-ms) {
      width: rem(285);
      height: rem(26);
    }
  }
}

.l-header__close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 2;

  @media ($min-s) {
    top: 2rem;
    right: 2.25rem;
  }
}
